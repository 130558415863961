import { Tabs, List } from 'antd';
import { CodeOutlined, MessageOutlined } from '@ant-design/icons';

import ModuleAiLibraryItem from './ModulesAiLibraryItem';
import { useDrop } from 'react-dnd';
import { useRef } from 'react';

const { TabPane } = Tabs;

const ModuleAiLibraryTable = ({ modules, onMove, onPreview }) => {
  const ref = useRef(null);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'CARD',
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item, monitor) => {
      item.remove();
    },
  });

  drop(ref);

  return (
    <div ref={ref}>
      <Tabs>
        <TabPane
          key="enabled"
          tab={
            <>
              <CodeOutlined /> {`Modules généraux (${modules.general.length})`}
            </>
          }
        >
          <List
            bordered
            size="small"
            dataSource={modules.general}
            itemLayout="horizontal"
            renderItem={(item) => {
              return (
                <ModuleAiLibraryItem
                  key={`module.${item._id}`}
                  moduleItem={item}
                  onMove={onMove}
                  onDelete={() => {}}
                  onPreview={() => onPreview(item)}
                />
              );
            }}
          />
        </TabPane>
        {modules?.application ? (
          <TabPane
            key="disabled"
            tab={
              <>
                <MessageOutlined /> {`Modules de l'application (${modules.application.length})`}
              </>
            }
          >
            <List
              bordered
              size="small"
              dataSource={modules.application}
              itemLayout="horizontal"
              renderItem={(item) => {
                return (
                  <ModuleAiLibraryItem
                    key={`module.${item._id}`}
                    moduleItem={item}
                    onMove={onMove}
                    onDelete={() => {}}
                    onPreview={() => onPreview(item)}
                  />
                );
              }}
            />
          </TabPane>
        ) : null}
      </Tabs>
    </div>
  );
};

export default ModuleAiLibraryTable;
