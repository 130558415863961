import { Col, Row } from 'antd';
import Catalog from './components/Catalog';
import Categories from './components/Categories';
import Seo from './components/Seo';
import Layout from '../components/Layout';

// TODO : Ajouter le SEO
const Home = () => {
  return (
    <Layout title="Gestion Inter/Intra">
      <Col span={24} xl={16}>
        <Categories />
      </Col>
      <Col span={24} xl={8}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Catalog />
          </Col>
          <Col span={24}>
            <Seo />
          </Col>
        </Row>
      </Col>
    </Layout>
  );
};

export default Home;
