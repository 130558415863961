import { Drawer, Layout, Card, Menu, Space, Typography } from 'antd';
import { memo, useState } from 'react';
import { format } from 'date-fns';

const getDisplayOptions = (role) => {
  const displayOptions = {
    title: undefined,
    style: {
      background: '#fafafa',
      borderRadius: '10px',
    },
  };

  if (role === 'user') {
    displayOptions.title = 'Utilisateur';
    displayOptions.style.background = '#e6f7ff';
  }

  if (role === 'assistant') {
    displayOptions.title = 'Assistant virtuel';
    displayOptions.style.background = '#fff7e6';
  }

  return displayOptions;
};

const MessageCard = ({ role, content, date }) => {
  const displayOptions = getDisplayOptions(role);

  return (
    <Card style={displayOptions.style}>
      {!!displayOptions.title && <Typography.Title level={5}>{displayOptions.title}</Typography.Title>}
      <Typography.Paragraph>{content}</Typography.Paragraph>
      {!!displayOptions.title && (
        <Typography.Text type="secondary">Envoyé le {format(new Date(date), 'dd/MM/yyyy à HH:mm')}</Typography.Text>
      )}
    </Card>
  );
};

const ConversationAiModal = memo(({ visible, data, onClose }) => {
  const [activeInteraction, setActiveInteraction] = useState('prompt');

  return (
    <Drawer
      open={visible}
      onClose={onClose}
      title=""
      placement="top"
      height="100vh"
      bodyStyle={{ padding: 0 }}
      destroyOnClose
    >
      <Layout style={{ background: '#ffffff' }}>
        <Layout.Sider width={250} theme="light" style={{ background: '#fff' }}>
          <Menu style={{ height: '100%', maxHeight: 'calc(100vh - 54.8px)', overflow: 'scroll' }}>
            <Menu.Item key="prompt-menu" onClick={() => setActiveInteraction(`prompt`)}>
              Prompt
            </Menu.Item>
            <Menu.Item key="messages-menu" onClick={() => setActiveInteraction(`messages`)}>
              Conversation
            </Menu.Item>
          </Menu>
        </Layout.Sider>
        <Layout.Content style={{ background: '#fff', height: 'calc(100vh - 54.8px)', padding: 24, overflow: 'scroll' }}>
          {activeInteraction === 'prompt' && <MessageCard content={data.prompt} />}
          {/* {activeInteraction === 'messages' && <MessageCard role={'user'} content={data.prompt} date={data.createdAt} />} */}
          {activeInteraction === 'messages' && (
            <Space direction="vertical" style={{ display: 'flex' }}>
              {(data?.messages || []).map((item) => {
                return <MessageCard role={item.role} content={item.content} date={item.createdAt} />;
              })}
            </Space>
          )}
        </Layout.Content>
      </Layout>
    </Drawer>
  );
});

export default ConversationAiModal;
