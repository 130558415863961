import { Card, Form, Button, Input, Space, Select, Typography, message, Col, Row, Divider, Tooltip } from 'antd';
import { Link, Prompt, useParams } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import { useForm, Controller, useFormState, useFieldArray, FormProvider } from 'react-hook-form';
import useSWR from 'swr';
import { QuestionCircleOutlined } from '@ant-design/icons';

import useFetch from '../../../hooks/useFetch';
import { aiRoutes, formationRoutes } from '../../../lib/routes';
import { searchInString } from '../../../lib/helpers';
import ModuleAiLibraryTable from '../components/ModulesAiConstruction/ModulesAiLibraryTable';
import ModuleAiLibraryList from '../components/ModulesAiConstruction/ModulesAiLibraryList';
import ModulePreviewModal from '../components/ModulePreviewModal';

import PromptTestModal from './components/PromptTestModal';

const { Item } = Form;
const { TextArea } = Input;
const { Paragraph } = Typography;

const getDefaultValues = (activity) => {
  if (!activity || !activity?.data) {
    return {};
  }

  return {
    title: activity.data.title,
    description: activity.data.description,
    application: activity.data?.application,
    formations: activity.data?.formations || [],
    modules: activity.data?.modules || [],
    disabled: activity.data?.disabled || false,
  };
};

const ActivityAiEdit = () => {
  const { workspace, id } = useParams();
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [promptTestModalOpen, setPromptTestModalOpen] = useState(false);
  const [modulePreviewModal, setModulePreviewModal] = useState(false);
  const { data: formations, isValidating: isFormationsValidating } = useSWR(formationRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: application, isValidating: isApplicationValidating } = useSWR(`${aiRoutes.applications}/${workspace}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: activity, isValidating: isActivitiesValidating } = useSWR(`${aiRoutes.activities}/${id}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: modules, isValidating: isModulesValidating } = useSWR(`${aiRoutes.modules}?app=general,${workspace}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const defaultValues = useMemo(() => getDefaultValues(activity), [activity?.data]);
  const methods = useForm({ defaultValues });
  const { handleSubmit, reset, control, setValue, setError, formState } = methods;
  const { fields: selectedModules, remove, append, move } = useFieldArray({ name: 'modules', control });

  // Construction de la bibliothèque de modules disponibles
  const modulesLibrary = useMemo(() => {
    const beforeModules = application?.data?.template?.before ? application.data.template.before : [];
    const afterModules = application?.data?.template?.after ? application.data.template.after : [];

    let generalModules = [];
    let applicationModules = [];

    if (modules?.data) {
      for (let mi = 0; mi < modules.data.length; mi++) {
        const module = modules.data[mi];

        // Liste les modules déjà utilisés
        const currentSelection = [...selectedModules, ...beforeModules, ...afterModules].map((item) => item._id);

        if (!module.disabled && !currentSelection.includes(module._id)) {
          if (module.application) {
            applicationModules.push(module);
          } else {
            generalModules.push(module);
          }
        }
      }
    }

    return { general: generalModules, application: applicationModules };
  }, [application, modules, modules?.data, workspace, selectedModules]);

  const { errors } = useFormState({ control });
  const { put } = useFetch();

  useEffect(() => {
    if (formState.isDirty) {
      setIsBlocking(true);
    }
  }, [formState.isDirty]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(`${aiRoutes.activities}/${id}`, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }
    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <PromptTestModal
          isOpen={!!promptTestModalOpen}
          input={promptTestModalOpen}
          onClose={() => setPromptTestModalOpen(false)}
        />
        <ModulePreviewModal
          isOpen={!!modulePreviewModal}
          input={modulePreviewModal}
          onClose={() => setModulePreviewModal(false)}
        />
        <Form noValidate layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Paragraph>
          <Row gutter={[24, 24]} align="bottom">
            <Col span={12}>
              <Item
                validateStatus={errors?.title?.message && 'error'}
                help={errors?.title?.message}
                label="Intitulé"
                required
              >
                <Controller
                  control={control}
                  name="title"
                  render={({ field }) => <Input {...field} disabled={isProcessing} />}
                />
              </Item>
              <Item
                validateStatus={errors?.description?.message && 'error'}
                help={errors?.description?.message}
                label="Description"
                required
              >
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => <TextArea {...field} disabled={isProcessing} />}
                />
              </Item>
              <Item
                label={
                  <>
                    Formation(s) liée(s)&nbsp;
                    <Tooltip title="Formations pouvant utiliser l'activité. Si aucune formation n'est listée, toutes les formations auront accès à l'activité">
                      <QuestionCircleOutlined style={{ color: '#1677ff' }} />
                    </Tooltip>
                  </>
                }
                validateStatus={errors?.formations?.message && 'error'}
                help={errors?.formations?.message}
              >
                <Controller
                  name="formations"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      mode="multiple"
                      filterOption={(input, option) => searchInString(input, option.label)}
                      options={formations?.data.map((item) => ({
                        value: item._id,
                        label: item.title,
                      }))}
                      allowClear
                      placeholder="Cette activité n'est liée à aucune formation"
                    />
                  )}
                />
              </Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[24, 24]} align="top">
            <Col span={6}>
              <Typography.Text>Bibliothèques de modules</Typography.Text>
              <ModuleAiLibraryTable modules={modulesLibrary} onPreview={setModulePreviewModal} />
            </Col>
            <Col span={6}>
              <Item
                label="Modules de l'activité"
                validateStatus={errors?.modules?.message && 'error'}
                help={errors?.modules?.message}
              >
                <ModuleAiLibraryList
                  modules={selectedModules}
                  onMove={move}
                  onAppend={append}
                  onDelete={remove}
                  onPreview={setModulePreviewModal}
                />
              </Item>
            </Col>
          </Row>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to={`/ia/activites/${workspace}`}>
              <Button disabled={isProcessing}>Annuler</Button>
            </Link>
            <Space size="middle">
              <Button onClick={() => setPromptTestModalOpen(true)}>Tester</Button>
              <Button type="primary" htmlType="submit" loading={isProcessing}>
                Enregistrer
              </Button>
            </Space>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default ActivityAiEdit;
