import { Card, Form, Button, Input, Typography, Space, message, Row, Col } from 'antd';
import { Link, Prompt, useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm, Controller, useFormState, FormProvider } from 'react-hook-form';

import useFetch from '../../../hooks/useFetch';
import { aiRoutes } from '../../../lib/routes';
import { htmlToContent } from '../lib/htmlParser';

import PromptTestModal from './components/PromptTestModal';
import ModuleContent from './components/ModuleContent';

const { Item } = Form;
const { TextArea } = Input;
const { Paragraph } = Typography;

const ModuleAiCreate = () => {
  const { workspace } = useParams();
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [promptTestModalOpen, setPromptTestModalOpen] = useState(false);
  const methods = useForm({
    defaultValues: { application: workspace === 'general' ? null : workspace },
  });
  const { handleSubmit, control, setValue, setError, formState } = methods;
  const { errors } = useFormState({ control });
  const { post } = useFetch();
  const history = useHistory();

  useEffect(() => {
    if (formState.isDirty) {
      setIsBlocking(true);
    }
  }, [formState.isDirty]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    // Formatte le texte du contenu
    form.content = htmlToContent(form.content, form.variables);

    const results = await post(aiRoutes.modules, JSON.stringify(form));

    if (results.status === 201) {
      setIsBlocking(false);
      history.push(`/ia/modules/${workspace}`);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <PromptTestModal
          isOpen={!!promptTestModalOpen}
          input={promptTestModalOpen}
          onClose={() => setPromptTestModalOpen(false)}
        />
        <Form noValidate layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Paragraph>
          <Row gutter={(24, 24)}>
            <Col span={12}>
              <Item
                validateStatus={errors?.title?.message && 'error'}
                help={errors?.title?.message}
                label="Intitulé"
                required
              >
                <Controller
                  control={control}
                  name="title"
                  render={({ field }) => (
                    <Input {...field} placeholder="Entrez le titre du module" disabled={isProcessing} />
                  )}
                />
              </Item>
              <ModuleContent />
            </Col>
          </Row>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to={`/ia/modules/${workspace}`}>
              <Button disabled={isProcessing}>Annuler</Button>
            </Link>
            <Space size="middle">
              <Button onClick={() => setPromptTestModalOpen(true)}>Tester</Button>
              <Button type="primary" htmlType="submit" loading={isProcessing}>
                Enregistrer
              </Button>
            </Space>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default ModuleAiCreate;
