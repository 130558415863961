import { Button, Card, Space, Typography } from 'antd';
import { useFormContext } from 'react-hook-form';

const SeoConfig = () => {
  const { control, disabled } = useFormContext();

  return (
    <Card size="small" title={<Typography.Title level={5}>Référencement (SEO)</Typography.Title>}>
      <Space direction="vertical" style={{ display: 'flex' }}>
        <Button {...{ disabled }} block>
          Modifier les balises META
        </Button>
        <Button {...{ disabled }} block>
          Configurer les données structurées
        </Button>
      </Space>
    </Card>
  );
};

export default SeoConfig;
