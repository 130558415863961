import { Button, Popconfirm, Space, Tooltip, message, Tag } from 'antd';
import { CopyOutlined, DeleteOutlined, EditOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { aiRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';

import Item, { Meta } from 'antd/lib/list/Item';
import { checkAuthorization } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';
import { useState } from 'react';

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const ModuleAiItem = ({ item, onMutate, workspace }) => {
  const { user } = useAuth();
  const { post, patch, remove } = useFetch();
  const [isProcessing, setIsProcessing] = useState(false);

  const enableActivity = async () => {
    setIsProcessing(true);

    const results = await patch(aiRoutes.activities + '/enable/' + item._id);

    if (results.status === 200) {
      onMutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const disableActivity = async () => {
    setIsProcessing(true);

    const results = await patch(aiRoutes.activities + '/disable/' + item._id);

    if (results.status === 200) {
      onMutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const duplicateActivity = async () => {
    setIsProcessing(true);

    const results = await post(
      aiRoutes.activities + '/' + item._id + '/copy',
      JSON.stringify({ title: item.title + ' (copy)' }),
    );

    if (results.status === 201) {
      onMutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const deleteActivity = async () => {
    setIsProcessing(true);

    const results = await remove(aiRoutes.activities + '/' + item._id);

    if (results.status === 200) {
      onMutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  return (
    <Item
      actions={[
        <Space size="small">
          {item.disabled
            ? checkAuthorization(user, 'ai', 'activities', 'enable') && (
                <Tooltip title="Activer l'activité" destroyTooltipOnHide={{ keepParent: false }}>
                  <Popconfirm {...popconfirmProps} onConfirm={enableActivity}>
                    <Button icon={<UnlockOutlined />} disabled={isProcessing} />
                  </Popconfirm>
                </Tooltip>
              )
            : checkAuthorization(user, 'ai', 'activities', 'disable') && (
                <Tooltip title="Désactiver l'activité" destroyTooltipOnHide={{ keepParent: false }}>
                  <Popconfirm {...popconfirmProps} onConfirm={disableActivity}>
                    <Button icon={<LockOutlined />} disabled={isProcessing} />
                  </Popconfirm>
                </Tooltip>
              )}
          {checkAuthorization(user, 'ai', 'activities', 'update') && (
            <Tooltip title="Modifier l'activité" destroyTooltipOnHide={{ keepParent: false }}>
              <Link to={`/ia/activites/${workspace}/modifier-une-activite/${item._id}`}>
                <Button icon={<EditOutlined />} disabled={isProcessing} />
              </Link>
            </Tooltip>
          )}
          {checkAuthorization(user, 'ai', 'activities', 'create') && (
            <Tooltip title="Dupliquer l'activité" destroyTooltipOnHide={{ keepParent: false }}>
              <Popconfirm {...popconfirmProps} onConfirm={duplicateActivity}>
                <Button icon={<CopyOutlined />} disabled={isProcessing} />
              </Popconfirm>
            </Tooltip>
          )}
          {checkAuthorization(user, 'ai', 'activities', 'delete') && (
            <Tooltip title="Supprimer l'activité" destroyTooltipOnHide={{ keepParent: false }}>
              <Popconfirm {...popconfirmProps} onConfirm={deleteActivity}>
                <Button icon={<DeleteOutlined />} disabled={isProcessing} />
              </Popconfirm>
            </Tooltip>
          )}
        </Space>,
      ]}
    >
      <Meta
        title={item.title}
        description={
          <Space direction="vertical">
            {item.description}
            <span>
              {item.formations?.map((formation) => (
                <Tag>{formation.acronym}</Tag>
              ))}
            </span>
          </Space>
        }
      />
    </Item>
  );
};

export default ModuleAiItem;
