import { Form, Card, List, Space } from 'antd';
import { useFieldArray, useController, useFormContext, useFormState } from 'react-hook-form';

import Editor from '../../../../ui/Editor';
import ModuleVariableListItem from './ModuleVariableListItem';

const ModuleContent = () => {
  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });
  const { field: contentField } = useController({ name: 'content', control });
  const { fields: variableFields, append, remove, update } = useFieldArray({ control, name: 'variables' });

  const onEditorChange = (value, onChange) => {
    // Mets le texte à jour
    onChange(value);

    // Récupère la liste des variables dans le texte
    const parser = new DOMParser();
    const content = parser.parseFromString(value, 'text/html');
    const spans = content.querySelectorAll('[data-type="variable"]');

    // Liste toutes les variables du texte
    const variables = [];
    for (let i = 0; i < spans.length; i++) {
      if (!spans[i].getAttribute('data-variable-ref')) {
        variables.push({
          id: spans[i].getAttribute('data-variable-id'),
          key: spans[i].textContent,
        });
      }
    }

    // Compare avec la liste des variables
    for (let i = 0; i < variables.length; i++) {
      const textVariable = variables[i];
      const listedVariable = variableFields.find((item) => item.variableId === textVariable.id);

      // Ajoute la nouvelle variable si non existante
      if (!listedVariable) {
        addVariable({ ...textVariable });
      } else {
        // Mets à jour l'entrée existante
        if (textVariable.key !== listedVariable.key) {
          update(i, { key: textVariable.key });
        }
      }
    }

    // Retire les anciennes variables
    for (let i = 0; i < variableFields.length; i++) {
      if (!variables.find((item) => item.id === variableFields[i].variableId)) {
        remove(i);
      }
    }
  };

  const addVariable = ({ key, id }) => {
    append({
      key: key || `Variable ${variableFields?.length + 1 || 1}`,
      variableId: id || null,
      type: 'RANDOM_NUMBER',
      // Défauts pour les nombres aléatoires
      min: 0,
      max: 10,
      isDecimal: false,
      digits: 2,
      // Défaut pour les listes d'éléments
      list: ['Premier élément', 'Second élément'],
      // Défaut pour les champs utilisateurs
      field: 'USER_NAME',
    });
  };

  return (
    <>
      <Form.Item
        validateStatus={errors?.content?.message && 'error'}
        help={errors?.content?.message}
        label="Contenu"
        required
      >
        <Editor
          {...contentField}
          onChange={(value) => onEditorChange(value, contentField.onChange)}
          minHeight={300}
          options={{
            heading: false,
            figure: false,
            strike: false,
            color: false,
            important: false,
            highlight: false,
            code: false,
            codeBlock: false,
            youtube: false,
            vimeo: false,
            cloudinaryVideo: false,
            blockquote: false,
            file: false,
            table: false,
            bold: false,
            italic: false,
            underline: false,
            textAlign: false,
            details: false,
            grid: false,
            link: false,
            orderedList: false,
            bulletList: false,
          }}
        />
      </Form.Item>
      <Form.Item label="Variable(s) du module">
        <Card size="small">
          <Space direction="vertical" style={{ display: 'flex' }}>
            <List
              bordered
              size="small"
              pagination={false}
              style={{ overflow: 'hidden' }}
              dataSource={variableFields}
              renderItem={(item, index) => (
                <ModuleVariableListItem {...{ item, index }} onDelete={() => remove(index)} />
              )}
            />
          </Space>
        </Card>
      </Form.Item>
    </>
  );
};

export default ModuleContent;
