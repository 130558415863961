import { Collapse, Modal, Typography } from 'antd';

const ModulePreviewModal = ({ input, isOpen, onClose }) => {
  const getDigitsText = (digits) => {
    if (digits === 0) {
      return <b>sans décimales</b>;
    }
    if (digits === 1) {
      return (
        <>
          avec <b>1 décimale</b>
        </>
      );
    }
    if (digits > 1) {
      return (
        <>
          avec <b>{digits} décimales</b>
        </>
      );
    }
  };

  return (
    <>
      <Modal
        title={`Prévisualisation du module « ${input?.title} »`}
        visible={isOpen}
        onCancel={onClose}
        // onOk={handleSubmit(onConfirm)}
        width={750}
        footer={false}
      >
        <Typography.Title level={5}>Contenu</Typography.Title>
        <Typography.Paragraph>
          <span dangerouslySetInnerHTML={{ __html: input.content?.replaceAll('\n', '<br/>') }} />
        </Typography.Paragraph>
        <Typography.Title level={5}>Variable(s)</Typography.Title>
        {input?.variables?.length ? (
          <Collapse className="module-preview-modal-collapse" ghost>
            {input.variables.map((variable, index) => (
              <Collapse.Panel header={variable.key} key={variable.key}>
                <div style={{ marginLeft: 20, borderLeft: '2px solid #D9D9D9', paddingLeft: 20 }}>
                  {variable.type === 'RANDOM_NUMBER' && (
                    <Typography.Text>
                      Nombre aléatoire entre <b>{variable.min}</b> et <b>{variable.max}</b>&nbsp;
                      {getDigitsText(variable.digits)}.
                    </Typography.Text>
                  )}
                  {variable.type === 'LIST' && (
                    <>
                      <Typography.Text>Valeur aléatoire parmis :</Typography.Text>
                      <ul>
                        {variable.list.map((item) => (
                          <li>{item}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : (
          <Typography.Text type="secondary">Aucune variable n'est utilisée dans ce module</Typography.Text>
        )}
      </Modal>
    </>
  );
};

export default ModulePreviewModal;
