import { useState } from 'react';
import { Card, Tabs } from 'antd';
import useSWR from 'swr';

import { aiRoutes } from '../../../lib/routes';
import { useAuth } from '../../../authContext';
import ImageGenerationAiTable from './ImageGenerationAiTable';
import ConversationsAiTable from './ConversationsAiTable';

const { TabPane } = Tabs;

const InteractionsAiList = () => {
  const { user } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: conversations, isValidating, mutate } = useSWR(aiRoutes.conversations);

  return (
    <Card>
      <Tabs>
        <TabPane key="conversation" tab={`Conversations (${conversations?.data?.length || 0})`}>
          <ConversationsAiTable
            conversations={conversations?.data || []}
            onMutate={mutate}
            isValidating={isValidating}
            isProcessing={isProcessing}
            onProcessing={setIsProcessing}
          />
        </TabPane>
        <TabPane disabled key="imageGeneration" tab={`Générations d'image (${[].length})`}>
          <ImageGenerationAiTable
            imageGenerations={[]}
            onMutate={mutate}
            isValidating={isValidating}
            isProcessing={isProcessing}
            onProcessing={setIsProcessing}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default InteractionsAiList;
