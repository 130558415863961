import React, { useState } from 'react';
import { Modal, Button, Tooltip, Checkbox, Typography, Tag, Tabs } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import rolesData from '../../../../datasets/roles.json';

const { TabPane } = Tabs;

const RoleModal = ({ role }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Tooltip title="Consulter" destroyTooltipOnHide={{ keepParent: false }}>
        <Button className="gray-text" icon={<EyeOutlined />} onClick={() => setIsOpen(true)} />
      </Tooltip>
      <Modal
        title={<Tag color={role.color}>{role.name}</Tag>}
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        footer={false}
        width={700}
        style={{ top: 24, margin: '0 auto' }}
        destroyOnClose
      >
        <div style={{ overflowY: 'auto' }}>
          <Tabs>
            <TabPane tab="Administration" key="admin">
              <Typography.Title level={5} className="gray-text">
                Permissions sur les utilisateurs
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.global.users}
                value={role.permissions.global.users}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les rôles
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.global.roles}
                value={role.permissions.global.roles}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les centres
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.global.agencies}
                value={role.permissions.global.agencies}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les clés d'accès
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.global.keys}
                value={role.permissions.global.keys}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les interventions externes
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.global.externalInterventions}
                value={role.permissions.global.externalInterventions}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
            </TabPane>
            <TabPane tab="Espace Arinfo" key="arinfo">
              <Typography.Title level={5} className="gray-text">
                Permissions sur les actualités
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.arinfo.posts}
                value={role.permissions.arinfo.posts}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les formations
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.arinfo.trainings}
                value={role.permissions.arinfo.trainings}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur la galerie des projets
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.arinfo.projects}
                value={role.permissions.arinfo.projects}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les statistiques des centres
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.arinfo.statistics}
                value={role.permissions.arinfo.statistics}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les pré-inscriptions
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.arinfo.registrations}
                value={role.permissions.arinfo.registrations}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur le simulateur CPF
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.arinfo.cpf}
                value={role.permissions.arinfo.cpf}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les ateliers découverte
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.arinfo.workshops}
                value={role.permissions.arinfo.workshops}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur la foire aux questions
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.arinfo.faq}
                value={role.permissions.arinfo.faq}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
            </TabPane>
            <TabPane tab="Espace Posiao" key="posiao">
              <Typography.Title level={5} className="gray-text">
                Permissions sur les auto-évaluations
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.posiao.evaluations}
                value={role.permissions.posiao.evaluations}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les types
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.posiao.types}
                value={role.permissions.posiao.types}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur la banque de questions
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.posiao.questions}
                value={role.permissions.posiao.questions}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les tests de positionnement
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.posiao.quizzes}
                value={role.permissions.posiao.quizzes}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
            </TabPane>
            <TabPane tab="Espace Formao" key="formao">
              <Typography.Title level={5} className="gray-text">
                Permissions sur les apprenants
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.formao.students}
                value={role.permissions.formao.students}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les sessions
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.formao.groups}
                value={role.permissions.formao.groups}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les formations
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.formao.formations}
                value={role.permissions.formao.formations}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les cours
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.formao.modules}
                value={role.permissions.formao.modules}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les évènements
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.formao.events}
                value={role.permissions.formao.events}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les documents
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.formao.documents}
                value={role.permissions.formao.documents}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les bilans de formation en continu
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.formao.formationReports}
                value={role.permissions.formao.formationReports}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les bilans de formation en alternance
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.formao.companyReports}
                value={role.permissions.formao.companyReports}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les suivis mensuel
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.formao.monthlyReports}
                value={role.permissions.formao.monthlyReports}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les bilans de stage
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.formao.trainingReports}
                value={role.permissions.formao.trainingReports}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les formulaires de bilans
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.formao.reportForms}
                value={role.permissions.formao.reportForms}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les bilans de satisfaction
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.formao.satisfactionReports}
                value={role.permissions.formao.satisfactionReports}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur l'import de packages
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.formao.packages}
                value={role.permissions.formao.packages}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
            </TabPane>
            <TabPane tab="Espace I-magineLab" key="imaginelab">
              <Typography.Title level={5} className="gray-text">
                Permissions sur les réservations
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.imaginelab.bookings}
                value={role.permissions.imaginelab.bookings}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les ateliers
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.imaginelab.workshops}
                value={role.permissions.imaginelab.workshops}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les documents
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.imaginelab.documents}
                value={role.permissions.imaginelab.documents}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
            </TabPane>
            <TabPane tab="Intelligences artificielles" key="ai">
              <Typography.Title level={5} className="gray-text">
                Permissions sur les systèmes
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.ai.systems}
                value={role.permissions.ai.systems}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les versions
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.ai.versions}
                value={role.permissions.ai.versions}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les clés API
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.ai.keys}
                value={role.permissions.ai.keys}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur la file d'attente des tâches IA
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.ai.api}
                value={role.permissions.ai.api}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les applications
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.ai.applications}
                value={role.permissions.ai.applications}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les activités
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.ai.activities}
                value={role.permissions.ai.activities}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les modules
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.ai.modules}
                value={role.permissions.ai.modules}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les interactions
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.ai.interactions}
                value={role.permissions.ai.interactions}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
              <Typography.Title level={5} className="gray-text">
                Permissions sur les logs
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.ai.logs}
                value={role.permissions.ai.logs}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
            </TabPane>
            <TabPane tab="Outils de développement" key="devTools">
              <Typography.Title level={5} className="gray-text">
                Permissions sur la file d'attente d'import/export
              </Typography.Title>
              <Checkbox.Group
                options={rolesData.devTools.importExportService}
                value={role.permissions.devTools.importExportService}
                className="vertical-group"
                style={{ marginBottom: 24 }}
              />
            </TabPane>
          </Tabs>
        </div>
      </Modal>
    </>
  );
};

export default RoleModal;
