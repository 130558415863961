import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Button, Card, Col, Form, message, Row, Space } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import MainConfig from './components/MainConfig';
import ModesConfig from './components/ModesConfig';
import GoalsConfig from './components/GoalsConfig';
import AdditionalConfig from './components/AdditionalConfig';
import CoverConfig from './components/CoverConfig';
import Layout from '../components/Layout';
import useFetch from '../../../../hooks/useFetch';
import SeoConfig from './components/SeoConfig';
import Catalog from './components/Catalog';

const PAGE_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/pages/inter-intra`;
const FORMATION_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/trainings`;

const defaultValues = (category, formation) => ({
  category,
  formation,
  cover: {
    alt: '',
    image: null,
  },
  description: '',
  target: '',
  minMaxDays: [null, null],
  modes: {
    Inter: {
      minPrice: null,
      minMaxDays: [null, null],
      schedules: [],
      disabled: true,
    },
    Intra: {
      minPrice: null,
      minMaxDays: [null, null],
      schedules: [],
      disabled: true,
    },
    Tutorat: {
      minPrice: null,
      minMaxDays: [null, null],
      schedules: [],
      disabled: true,
    },
  },
  goals: [],
  extras: {
    favorite: false,
    certified: false,
  },
});

const SWROptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

// TODO : Page blocker
// TODO : Revoir les tooltips d'aide
// TODO : Ajouter les chargements
// TODO : Ajouter la plaquette
// TODO : Render l'image requise ? Ou mettre l'image de la formation par défaut
// TODO : Rentre le text alt requis
// TODO : Ajouter le SEO
// TODO : Ajouter la ou les plaquettes (attente de réponse Marion)
export default function CreateOrUpdateFormation() {
  const [mode, setMode] = useState(null);
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const { categoryId, formationId } = useParams();
  const { data: category, isValidating: isCategoryLoading } = useSWR(
    `${PAGE_URL}/categories/${categoryId}`,
    SWROptions,
  );
  const { data: categoryFormations, isValidating: isCategoryFormationLoading } = useSWR(
    `${PAGE_URL}/categories/${categoryId}/formations`,
    SWROptions,
  );
  const { data: formation, isValidating: isFormationLoading } = useSWR(`${FORMATION_URL}/${formationId}`, SWROptions);
  const methods = useForm({ defaultValues: defaultValues(categoryId, formationId) });
  const { handleSubmit, setError, reset } = methods;
  const { post, put } = useFetch();
  const history = useHistory();
  const isLoading = isCategoryLoading || isCategoryFormationLoading || isFormationLoading;

  useEffect(() => {
    if (!isLoading) {
      if (!category?.data || !formation?.data) {
        setTitle(!category?.data ? 'Catégorie inexistante' : 'Formation inexistante');
        setUrl('');
        return setMode('NOT_FOUND');
      }

      setUrl(`/${category?.data.value}/${formation?.data.seo.slug}`);

      const categoryFormation = (categoryFormations?.data || []).find((item) => item.formation === formationId);

      if (!categoryFormations?.data || !categoryFormation) {
        setTitle(`Ajout de la formation ${formation?.data.title} dans ${category?.data.name}`);
        return setMode('CREATE');
      }

      setTitle(`Modification de la formation ${formation?.data.title} dans ${category?.data.name}`);
      reset(categoryFormation);
      return setMode('EDIT');
    }
  }, [isLoading, category, categoryFormations, formation]);

  const onSaveAndLeave = async (form) => {
    const body = JSON.stringify(form);
    let response;

    if (mode === 'CREATE') {
      response = await post(`${PAGE_URL}/formations`, body);
    } else {
      response = await put(`${PAGE_URL}/formations/${form._id}`, body);
    }

    if (response.status === 201 || response.status === 200) {
      // setIsBlocking(false);

      if (mode === 'CREATE') {
        message.success('Formation ajoutée');
      } else {
        message.success('Formation modifiée');
      }

      history.push('/arinfo/inter-intra');
    } else {
      if (response.message) {
        message.error(response.message);
      } else {
        Object.entries(response.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }
  };

  const onSave = async (form) => {
    const body = JSON.stringify(form);
    const response = await put(`${PAGE_URL}/formations/${form._id}`, body);

    if (response.status === 200) {
      // setIsBlocking(false);

      message.success('Formation modifiée');
    } else {
      if (response.message) {
        message.error(response.message);
      } else {
        Object.entries(response.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }
  };

  return (
    <Layout
      {...{ url, title }}
      titleLoading={isLoading || mode === null || title === null}
      urlLoading={isLoading || mode === null || url === null}
      previewUrl={mode === 'CREATE'}
    >
      <FormProvider {...methods} disabled={isLoading || mode === 'NOT_FOUND' || mode === null}>
        <Col span={24}>
          <Form noValidate layout="vertical">
            <Row gutter={[24, 24]}>
              <Col span={16}>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <MainConfig />
                  </Col>
                  <Col span={24}>
                    <ModesConfig />
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <CoverConfig />
                  </Col>
                  <Col span={24}>
                    <Catalog />
                  </Col>
                  <Col span={24}>
                    <GoalsConfig />
                  </Col>
                  <Col span={24}>
                    <AdditionalConfig />
                  </Col>
                  <Col span={24}>
                    <SeoConfig />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Card size="small">
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Link to="/arinfo/inter-intra">
                      <Button>Annuler</Button>
                    </Link>
                    <Space>
                      {mode === 'EDIT' && (
                        <Button
                          onClick={handleSubmit(onSave)}
                          disabled={isLoading || mode === 'NOT_FOUND' || mode === null}
                        >
                          Enregistrer
                        </Button>
                      )}
                      <Button
                        type="primary"
                        onClick={handleSubmit(onSaveAndLeave)}
                        disabled={isLoading || mode === 'NOT_FOUND' || mode === null}
                      >
                        Enregistrer et quitter
                      </Button>
                    </Space>
                  </div>
                </Card>
              </Col>
            </Row>
          </Form>
        </Col>
      </FormProvider>
    </Layout>
  );
}
