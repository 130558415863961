import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Select,
  Typography,
  Space,
  Tabs,
  Input,
  Checkbox,
  Row,
  Col,
  Upload,
  Divider,
  DatePicker,
  message,
  Alert,
  Card,
  Collapse,
  Table,
  Tooltip,
} from 'antd';
import { useForm, Controller, useWatch, useFieldArray, useFormState } from 'react-hook-form';
import { FilePdfOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import ImgCrop from 'antd-img-crop';
import { serialize } from 'object-to-formdata';
import useSWR from 'swr';
import moment from 'moment';
import useFetch from '../../../hooks/useFetch';
import { sort, sortDateFormat } from '../../../shared/utils';
import { agencyRoutes, trainingRoutes } from '../../../lib/routes';

const jobCategories = [
  { label: 'Art graphique & web', value: 'art-graphique-et-web' },
  { label: 'Architecture & bâtiment', value: 'architecture-et-batiment' },
  { label: 'Développement web & logiciels', value: 'dev-web-logiciels' },
  { label: 'Numérique & bureautique', value: 'numerique-et-bureautique' },
  { label: 'Marketing & communication', value: 'marketing-et-communication' },
  { label: 'Parcoursup', value: 'parcoursup' },
];

const toolCategories = [
  { label: 'Architecture & bâtiment', value: 'architecture-et-batiment' },
  { label: 'Arts appliqués', value: 'arts-appliques' },
  { label: 'Numérique & bureautique', value: 'numerique-et-bureautique' },
  { label: 'Web & logiciels', value: 'web-et-logiciels' },
];

const sources = [
  { label: 'Bilan d’évaluation Arinfo', value: 'arinfo' },
  { label: 'La Bonne Formation', value: 'la-bonne-formation' },
  { label: 'Avis Google', value: 'google' },
];

const AllSessionsManager = ({ control, loading }) => {
  const {
    fields: sessions,
    append: appendSession,
    remove: removeSession,
  } = useFieldArray({
    control,
    name: `sessions.*.dates`,
  });

  return (
    <>
      <div style={{ padding: 16 }}>
        <Controller
          control={control}
          name={`sessions.*.custom`}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={field.value}
              disabled={loading}
              onChange={(e) => field.onChange(e.target.checked)}
            >
              Entrées / sorties permanentes
            </Checkbox>
          )}
        />
        <br />
        <Controller
          control={control}
          name={`sessions.*.remote`}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={field.value}
              disabled={loading}
              onChange={(e) => field.onChange(e.target.checked)}
            >
              Disponible en distanciel
            </Checkbox>
          )}
        />
      </div>
      <Table
        dataSource={sessions.map((session, index) => ({ ...session, index })) || []}
        size="small"
        pagination={{ pageSize: 5 }}
        rowKey="id"
      >
        <Table.Column
          title="Début"
          dataIndex="start"
          key="start"
          sorter={(a, b) => sortDateFormat(a, b, 'start')}
          sortDirections={['ascend', 'descend']}
          render={(value, record) => (
            <Controller
              control={control}
              name={`sessions.*.dates.${record.index}.start`}
              render={({ field }) => (
                <DatePicker
                  size="small"
                  {...field}
                  style={{ width: '100%' }}
                  value={moment(field.value, 'DD/MM/YYYY')}
                  onChange={(date, dateString) => field.onChange(dateString)}
                  format={['DD/MM/YYYY']}
                  disabled={loading}
                />
              )}
            />
          )}
        />
        <Table.Column
          title="Fin"
          dataIndex="end"
          key="end"
          sorter={(a, b) => sortDateFormat(a, b, 'end')}
          sortDirections={['ascend', 'descend']}
          render={(value, record) => (
            <Controller
              control={control}
              name={`sessions.*.dates.${record.index}.end`}
              render={({ field }) => (
                <DatePicker
                  size="small"
                  {...field}
                  style={{ width: '100%' }}
                  value={moment(field.value, 'DD/MM/YYYY')}
                  onChange={(date, dateString) => field.onChange(dateString)}
                  format={['DD/MM/YYYY']}
                  disabled={loading}
                />
              )}
            />
          )}
        />
        <Table.Column
          title="Alternance"
          dataIndex="alternation"
          key="alternation"
          align="center"
          width={100}
          render={(value, record) => (
            <Controller
              control={control}
              name={`sessions.*.dates.${record.index}.alternation`}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  disabled={loading}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          )}
        />
        <Table.Column
          title={
            <Tooltip title="Nouvelle session" placement="left">
              <Button
                icon={<PlusOutlined />}
                onClick={() =>
                  appendSession({
                    start: moment(new Date()).format('DD/MM/YYYY'),
                    end: moment(new Date()).format('DD/MM/YYYY'),
                    alternation: false,
                  })
                }
                disabled={loading}
              />
            </Tooltip>
          }
          align="center"
          width={50}
          render={(record) => (
            <Space>
              <Tooltip title="Supprimer cette session" placement="left">
                <Button icon={<DeleteOutlined />} onClick={() => removeSession(record.index)} disabled={loading} />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
    </>
  );
};

const SessionsManager = ({ control, agency, loading }) => {
  const {
    fields: sessions,
    append: appendSession,
    remove: removeSession,
  } = useFieldArray({
    control,
    name: `sessions.${agency._id}.dates`,
  });

  return (
    <>
      <div style={{ padding: 16 }}>
        <Controller
          control={control}
          name={`sessions.${agency._id}.custom`}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={field.value}
              disabled={loading}
              onChange={(e) => field.onChange(e.target.checked)}
            >
              Entrées / sorties permanentes
            </Checkbox>
          )}
        />
        <br />
        <Controller
          control={control}
          name={`sessions.${agency._id}.remote`}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={field.value}
              disabled={loading}
              onChange={(e) => field.onChange(e.target.checked)}
            >
              Disponible en distanciel
            </Checkbox>
          )}
        />
      </div>
      <Table
        dataSource={sessions.map((session, index) => ({ ...session, index })) || []}
        style={{ borderTop: '1px solid #f0f0f0' }}
        size="small"
        pagination={{ pageSize: 5 }}
        rowKey="id"
      >
        <Table.Column
          title="Début"
          dataIndex="start"
          key="start"
          sorter={(a, b) => sortDateFormat(a, b, 'start')}
          sortDirections={['ascend', 'descend']}
          render={(value, record) => (
            <Controller
              control={control}
              name={`sessions.${agency._id}.dates.${record.index}.start`}
              render={({ field }) => (
                <DatePicker
                  size="small"
                  {...field}
                  style={{ width: '100%' }}
                  value={moment(field.value, 'DD/MM/YYYY')}
                  onChange={(date, dateString) => field.onChange(dateString)}
                  format={['DD/MM/YYYY']}
                  disabled={loading}
                />
              )}
            />
          )}
        />
        <Table.Column
          title="Fin"
          dataIndex="end"
          key="end"
          sorter={(a, b) => sortDateFormat(a, b, 'end')}
          sortDirections={['ascend', 'descend']}
          render={(value, record) => (
            <Controller
              control={control}
              name={`sessions.${agency._id}.dates.${record.index}.end`}
              render={({ field }) => (
                <DatePicker
                  size="small"
                  {...field}
                  style={{ width: '100%' }}
                  value={moment(field.value, 'DD/MM/YYYY')}
                  onChange={(date, dateString) => field.onChange(dateString)}
                  format={['DD/MM/YYYY']}
                  disabled={loading}
                />
              )}
            />
          )}
        />
        <Table.Column
          title="Alternance"
          dataIndex="alternation"
          key="alternation"
          align="center"
          width={100}
          render={(value, record) => (
            <Controller
              control={control}
              name={`sessions.${agency._id}.dates.${record.index}.alternation`}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  disabled={loading}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          )}
        />
        <Table.Column
          title={
            <Tooltip title="Nouvelle session" placement="left">
              <Button
                icon={<PlusOutlined />}
                onClick={() =>
                  appendSession({
                    start: moment(new Date()).format('DD/MM/YYYY'),
                    end: moment(new Date()).format('DD/MM/YYYY'),
                    alternation: false,
                  })
                }
                disabled={loading}
              />
            </Tooltip>
          }
          align="center"
          width={50}
          render={(record) => (
            <Space>
              <Tooltip title="Supprimer cette session" placement="left">
                <Button icon={<DeleteOutlined />} onClick={() => removeSession(record.index)} disabled={loading} />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
    </>
  );
};

const TrainingCreate = () => {
  const { data: agencies } = useSWR(agencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [activeTab, setActiveTab] = useState('infos');
  const { post } = useFetch();
  const { handleSubmit, control, setValue, setError, watch } = useForm({
    defaultValues: {
      type: 'metier',
      sessions: {},
      testimonial: [],
      registration_type: 'INTERNE',
      program: [{ title: '', content: '' }],
      customer_availability: true,
      company_availability: true,
      alternation_availability: true,
      uninterrupted_availability: true,
      remote_availability: true,
      person_availability: true,
      duration_implementation:
        '<ul><li><strong>En continu</strong> : 700h en centre + 140h de stage en entreprise</li><li><strong>En alternance</strong> : 455h en centre + 1365h en entreprise (durée globale : 1 an)</li></ul><p>(démarrage d’une session en alternance à chaque début de session en continu ; voir ci-dessous)</p><ul><li>Parcours personnalisable selon niveau et projet professionnel</li></ul>',
      prerequisite_access:
        '<ul><li><strong>Prérequis </strong>: VOIR PLAQUETTE METIER</li><li><strong>Modalités d’accès </strong>: <a data-mce-href="https://arinfo.fr/nous-contacter" href="https://arinfo.fr/nous-contacter">centre</a>; admissibilité après étude personnalisée du profil et du projet professionnel (entretien, CV, motivation); admission en formation après validation du financement du parcours et signature du contrat individuel ou de la convention de formation ; délai d’accès variable selon le calendrier de la formation et le dispositif de financement mobilisé (entre 15 jours et 4 mois).</li></ul>',
      prices_funding:
        '<ul><li><strong>Tarifs </strong>:</li></ul><p>Formation en continu : taux horaire de 15€HT par candidat</p><p>Financement personnel ou demandeurs d’emploi : tarifs spécifiques à consulter auprès du <a data-mce-href="https://arinfo.fr/nous-contacter" href="https://arinfo.fr/nous-contacter">centre</a> concerné pour un accompagnement personnalisé</p><p>Alternance : tarifs spécifiques basés sur les coûts contrats des branches professionnelles, sans coût pédagogique pour l’alternant (<a href="http://www.francecompetences.fr/fiche/apprentissage-nouvelle-version-du-referentiel-des-niveaux-de-prise-en-charge-des-contrats/" data-mce-href="http://www.francecompetences.fr/fiche/apprentissage-nouvelle-version-du-referentiel-des-niveaux-de-prise-en-charge-des-contrats/">site France Compétences</a>); consultez le <a data-mce-href="https://arinfo.fr/nous-contacter" href="https://arinfo.fr/nous-contacter">centre</a> concerné pour plus de précision<strong></strong></p><ul><li><strong>Financements et dispositifs&nbsp;: </strong>consultation de la <a data-mce-href="https://arinfo.fr/financer-sa-formation" href="https://arinfo.fr/financer-sa-formation">page</a> dédiée<br></li></ul>',
      evaluation_validation:
        '<ul><li><strong>Evaluation des acquis </strong>: tout au long du parcours, tests d’acquisition des savoirs et mesures des savoir-faire lors de situations de mise en application pratique (TPs, projets).</li><li><strong>Fin de formation </strong>: attestation de fin de formation avec rappel des objectifs de formation et des modalités de réalisation (dates et durée de formation, lieu de formation)</li><li><strong>Validation </strong>: titre professionnel XXXX du Ministère chargé de l’Emploi (reconnu par l’Etat et inscrit au répertoire national des certifications professionnelles (RNCP)), de niveau XX (bac+XX) ; possibilité de validation du titre complet ou de validation partielle (certificat de compétences professionnelles (CCP))</li><li><strong>Modalités d’examen </strong>: modalités conformes au référentiel de certification du titre visé; mise en situation professionnelle (ou projet différé), résultats d’évaluation passées en formation, dossier professionnel (et annexes éventuelles) et entretien devant jury professionnel habilité; présentation détaillée des modalités de certification lors de l’entretien de candidature et en cours de parcours de formation.</li></ul>',
      methods_means:
        '<ul><li><strong>Pédagogie </strong>: un apprentissage métier proactif, basé sur le concept du «&nbsp;learning by doing&nbsp;»&nbsp;(les apprenants sont placés au cœur du processus de formation, en situation d’apprentissage actif).</li><li><strong>Formations multimodales </strong>: en présentiel (en centre, 9h-17h) ou en téléprésentiel (à distance, en salle virtuelle, 9h-17h)&nbsp;; mixité des séquences de formation (mises en situation professionnelles, projets, classes inversées, ateliers...).</li><li><strong>Moyens pédagogiques </strong>: formateurs spécialisés dans leur domaine; plateforme pédagogique avec accès individuel aux ressources de formation et une progression personnalisée; outils de suivi collectif et individuels (espaces d’échanges et de partage en ligne, salles virtuelles, livrets); présentation détaillée des modalités pédagogiques lors de l’entretien de candidature.</li></ul><p><em><a data-mce-href="https://arinfo.fr/pedagogie" href="https://arinfo.fr/pedagogie">En savoir plus</a><br></em></p>',
      satisfaction_success:
        '<ul><li><strong>Evaluation de la satisfaction des formations : </strong>collecte de la satisfaction des participations, en fin de formation, au travers de différents critères d’appréciation (durée de la formation, contenu des ressources, environnement de formation, formateurs, déroulement des travaux, investissement des participants) et recueil des avis sur le déroulement de la formation.</li><li><strong>Réussite aux examens : </strong>sous agrément de la Direccte, organisation des sessions d’examen de validation du titre professionnel, selon les modalités prévues au référentiel de certification; synthèse statistique de réussite en libre consultation dans chaque centre</li><li><strong>Les chiffres-clé 2020 : </strong>XX% de satisfaction et XX% de réussite aux examens</li></ul><p><a data-mce-href="https://arinfo.fr/pedagogie" href="https://arinfo.fr/pedagogie" data-mce-selected="inline-boundary"><em>En savoir plus</em></a><em></em></p>',
      references_labels:
        '<ul><li><strong>RNCP ou RSCH </strong>: XXXXX</li><li><strong>Code Certif Info </strong>: XXXXX</li><li><strong>Label </strong>: XXXXX</li></ul><p><em><a data-mce-href="https://arinfo.fr/pedagogie" href="https://arinfo.fr/pedagogie">En savoir plus sur les agréments et labels d’Arinfo I-maginer</a><br></em></p>',
      after_training:
        '<ul><li><strong>Métiers visés </strong>: VOIR PLAQUETTE METIER</li><li><strong>Débouchés </strong>: insertion professionnelle; création d’entreprise</li><li><strong>Passerelles et poursuite d’études </strong>: vers des formations accessibles à partir d’un diplôme de niveau Bac+XX</li></ul><p>RETIRER LES BLOCS NON LIÉS <span class="aCOpRe">À</span> LA FORMATION<br></p><p>TEBDP : Titres pro. BIM Modeleur, chargé d’affaires en rénovation énergétique du bâtiment, conducteur de travaux, chargé d’affaires du bâtiment; licences dans le domaine du bâtiment, de l’architecture et de la rénovation énergétique</p><p>IMEP : Titres pro. Designer web ; BTS et licences ans le domaine du graphisme, de l’audio visuel, de l’animation, de la communication, de la publicité</p><p>DW : Titre pro. Développeur web et web mobile ; licences pro. dans le domaine de la communication web, de l’animation, du graphisme</p><p>DWWM : Titre pro. Concepteur développeur d’applications ; licences pro. dans le domaine du développement web, mobile, informatique</p><p>CDA : masters dans le domaine du développement, du développement web, mobile, des bases de données ; écoles d’ingénieurs</p>',
      disabled_availability:
        '<ul><li><strong>Lieux de formation </strong>: centres de formation conformes aux règles d’accessibilité aux personnes handicapées</li><li><strong>Aménagement et adaptation </strong>: accompagnement personnalisé ; mise en relation avec les éventuelles structures connexes nécessaires à la gestion du handicap dans le cadre de la mise en œuvre du projet de formation ; possibilité d’aménagement du parcours (exemples : accueil à temps partiel ou discontinu, conditions d’examen, tutorat spécifique, adaptation du poste de formation...).</li><li><strong>Contact Référente handicap </strong>: Mme Marie DELOUCHE (mdelouche@arinfo.fr)</li></ul>',
    },
  });
  const allSessions = watch('sessions.*.dates');
  const { errors } = useFormState({ control });
  const {
    fields: program,
    append: appendProgram,
    remove: removeProgram,
  } = useFieldArray({
    control,
    name: 'program',
  });
  const {
    fields: testimonial,
    append: appendTestimonial,
    remove: removeTestimonial,
  } = useFieldArray({
    control,
    name: 'testimonial',
  });
  const type = useWatch({ control, name: 'type' });
  const registrationType = useWatch({ control, name: 'registration_type' });
  const history = useHistory();

  const onImageChange = (file) => {
    setValue('image', file);
    const reader = new FileReader();
    reader.addEventListener('load', () => setImagePreview(reader.result));
    reader.readAsDataURL(file);
  };

  const onFileChange = ({ file }) => {
    setValue('file', file.originFileObj);
  };

  useEffect(() => {
    // prettier-ignore
    if (type === "metier") {
      setValue('duration_implementation', "<ul><li><strong>En continu</strong> : 700h en centre + 140h de stage en entreprise</li><li><strong>En alternance</strong> : 455h en centre + 1365h en entreprise (durée globale : 1 an)</li></ul><p>(démarrage d’une session en alternance à chaque début de session en continu ; voir ci-dessous)</p><ul><li>Parcours personnalisable selon niveau et projet professionnel</li></ul>")
      setValue('prerequisite_access', '<ul><li><strong>Prérequis </strong>: VOIR PLAQUETTE METIER</li><li><strong>Modalités d’accès </strong>: <a data-mce-href="https://arinfo.fr/nous-contacter" href="https://arinfo.fr/nous-contacter">centre</a>; admissibilité après étude personnalisée du profil et du projet professionnel (entretien, CV, motivation); admission en formation après validation du financement du parcours et signature du contrat individuel ou de la convention de formation ; délai d’accès variable selon le calendrier de la formation et le dispositif de financement mobilisé (entre 15 jours et 4 mois).</li></ul>')
      setValue('prices_funding', '<ul><li><strong>Tarifs </strong>:</li></ul><p>Formation en continu : taux horaire de 15€HT par candidat</p><p>Financement personnel ou demandeurs d’emploi : tarifs spécifiques à consulter auprès du <a data-mce-href="https://arinfo.fr/nous-contacter" href="https://arinfo.fr/nous-contacter">centre</a> concerné pour un accompagnement personnalisé</p><p>Alternance : tarifs spécifiques basés sur les coûts contrats des branches professionnelles, sans coût pédagogique pour l’alternant (<a href="http://www.francecompetences.fr/fiche/apprentissage-nouvelle-version-du-referentiel-des-niveaux-de-prise-en-charge-des-contrats/" data-mce-href="http://www.francecompetences.fr/fiche/apprentissage-nouvelle-version-du-referentiel-des-niveaux-de-prise-en-charge-des-contrats/">site France Compétences</a>); consultez le <a data-mce-href="https://arinfo.fr/nous-contacter" href="https://arinfo.fr/nous-contacter">centre</a> concerné pour plus de précision<strong></strong></p><ul><li><strong>Financements et dispositifs&nbsp;: </strong>consultation de la <a data-mce-href="https://arinfo.fr/financer-sa-formation" href="https://arinfo.fr/financer-sa-formation">page</a> dédiée<br></li></ul>')
      setValue('evaluation_validation', "<ul><li><strong>Evaluation des acquis </strong>: tout au long du parcours, tests d’acquisition des savoirs et mesures des savoir-faire lors de situations de mise en application pratique (TPs, projets).</li><li><strong>Fin de formation </strong>: attestation de fin de formation avec rappel des objectifs de formation et des modalités de réalisation (dates et durée de formation, lieu de formation)</li><li><strong>Validation </strong>: titre professionnel XXXX du Ministère chargé de l’Emploi (reconnu par l’Etat et inscrit au répertoire national des certifications professionnelles (RNCP)), de niveau XX (bac+XX) ; possibilité de validation du titre complet ou de validation partielle (certificat de compétences professionnelles (CCP))</li><li><strong>Modalités d’examen </strong>: modalités conformes au référentiel de certification du titre visé; mise en situation professionnelle (ou projet différé), résultats d’évaluation passées en formation, dossier professionnel (et annexes éventuelles) et entretien devant jury professionnel habilité; présentation détaillée des modalités de certification lors de l’entretien de candidature et en cours de parcours de formation.</li></ul>")
      setValue('satisfaction_success', '<ul><li><strong>Evaluation de la satisfaction des formations : </strong>collecte de la satisfaction des participations, en fin de formation, au travers de différents critères d’appréciation (durée de la formation, contenu des ressources, environnement de formation, formateurs, déroulement des travaux, investissement des participants) et recueil des avis sur le déroulement de la formation.</li><li><strong>Réussite aux examens : </strong>sous agrément de la Direccte, organisation des sessions d’examen de validation du titre professionnel, selon les modalités prévues au référentiel de certification; synthèse statistique de réussite en libre consultation dans chaque centre</li><li><strong>Les chiffres-clé 2020 : </strong>XX% de satisfaction et XX% de réussite aux examens</li></ul><p><a data-mce-href="https://arinfo.fr/pedagogie" href="https://arinfo.fr/pedagogie" data-mce-selected="inline-boundary"><em>En savoir plus</em></a><em></em></p>')
      setValue('after_training', '<ul><li><strong>Métiers visés </strong>: VOIR PLAQUETTE METIER</li><li><strong>Débouchés </strong>: insertion professionnelle; création d’entreprise</li><li><strong>Passerelles et poursuite d’études </strong>: vers des formations accessibles à partir d’un diplôme de niveau Bac+XX</li></ul><p>RETIRER LES BLOCS NON LIÉS <span class="aCOpRe">À</span> LA FORMATION<br></p><p>TEBDP : Titres pro. BIM Modeleur, chargé d’affaires en rénovation énergétique du bâtiment, conducteur de travaux, chargé d’affaires du bâtiment; licences dans le domaine du bâtiment, de l’architecture et de la rénovation énergétique</p><p>IMEP : Titres pro. Designer web ; BTS et licences ans le domaine du graphisme, de l’audio visuel, de l’animation, de la communication, de la publicité</p><p>DW : Titre pro. Développeur web et web mobile ; licences pro. dans le domaine de la communication web, de l’animation, du graphisme</p><p>DWWM : Titre pro. Concepteur développeur d’applications ; licences pro. dans le domaine du développement web, mobile, informatique</p><p>CDA : masters dans le domaine du développement, du développement web, mobile, des bases de données ; écoles d’ingénieurs</p>')
    } else {
      setValue('duration_implementation', '<ul><li>Durée variable selon le profil et projet professionnel et selon la modalité d’accompagnement choisie (<a data-mce-href="https://arinfo.fr/pedagogie" href="https://arinfo.fr/pedagogie">en savoir plus</a>)<br></li><li>Durée recommandée en tutorat : XX h</li></ul>')
      setValue('prerequisite_access', '<ul><li><strong>Prérequis </strong>: voir fiche programme</li><li><strong>Modalités d’accès </strong>: contact auprès du <a data-mce-href="https://arinfo.fr/nous-contacter" href="https://arinfo.fr/nous-contacter">centre</a> souhaité; admissibilité après étude personnalisée du profil et du projet professionnel (entretien, CV, lettre de motivation) ; admission en formation après validation du financement de la formation et signature du contrat individuel ou de la convention de formation ; délai d’accès variable selon le projet et le dispositif de financement mobilisé (entre 2 jours et 1 mois).</li></ul>')
      setValue('prices_funding', '<ul><li><strong>Tarifs </strong>: à partir de 550€HT sauf 650€HT pour AE et PR et 825€HT pour Revit/archi (pour plus de précision, contact auprès du <a data-mce-href="https://arinfo.fr/nous-contacter" href="https://arinfo.fr/nous-contacter">centre</a> souhaité pour un accompagnement personnalisé)</li><li><strong>Financements et dispositifs </strong>: consultation de la page dédiée (<em><a data-mce-href="https://arinfo.fr/financer-sa-formation" href="https://arinfo.fr/financer-sa-formation">en savoir plus</a>)</em></li></ul>')
      setValue('evaluation_validation', "<ul><li><strong>Evaluation des acquis</strong> : tout au long du parcours, suivi des acquis sur la base des productions réalisées en formation en contexte de projets, mises en application...</li><li><strong>Fin de formation </strong>: attestation de fin de formation avec rappel des objectifs de formation et des modalités de réalisation (dates et durée de formation, lieu de formation)</li><li><strong>Validation&nbsp;</strong>: passage de la certification TOSA ou ICDL (PCIE) selon la formation suivie</li><li><strong>Modalités d’examen </strong>: modalités conformes aux conditions du certificateur; épreuve réalisée sur poste informatique; durée moyenne : 1h par épreuve.</li></ul>")
      setValue('satisfaction_success', '<ul><li><strong>Evaluation de la satisfaction des formations&nbsp;: </strong>collecte de la satisfaction des participations, en fin de formation, au travers de différents critères d’appréciation (durée de la formation, contenu des ressources, environnement de formation, formateurs, déroulement des travaux, investissement des participants) et recueil des avis sur le déroulement de la formation.</li><li><strong>Réussite aux examens&nbsp;: </strong>synthèse statistique de réussite (tous centres confondus).</li><li><strong>Les chiffres-clé 2020&nbsp;: </strong>xx% de satisfaction globale et xx% de réussite aux tests TOSA / ICDL</li></ul><p><a data-mce-href="https://arinfo.fr/pedagogie" href="https://arinfo.fr/pedagogie" data-mce-selected="inline-boundary"><em>En savoir plus</em></a><em></em></p>')
      setValue('after_training', '<ul><li><strong>Domaines d’application indicatifs </strong>: voir fiche formation</li><li><strong>Autres formations en lien avec celle suivie&nbsp;</strong>: selon outil</li></ul>')
    }
  }, [type]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const formData = serialize(form, { indices: true });
    const results = await post(trainingRoutes.create, formData, 'multipart/form-data');

    if (results.status === 201) {
      history.push('/arinfo/formations');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card className="import-form">
      <Form noValidate onFinish={handleSubmit(onSubmit)} layout="vertical">
        <Typography.Paragraph type="secondary">
          <blockquote style={{ marginTop: 0 }}>
            <ul>
              <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              <li>
                ATTENTION, le changement du type de formation réinitialisera les champs de l'onglet "Contenu" qui ont
                déjà été remplis
              </li>
            </ul>
          </blockquote>
        </Typography.Paragraph>
        {Object.keys(errors).length !== 0 ? <Alert message="Des erreurs sont présentes" type="error" /> : null}
        <Tabs activeKey={activeTab} onChange={(tab) => setActiveTab(tab)}>
          <Tabs.TabPane tab="Informations" key="infos" forceRender>
            <Form.Item
              label="Type de formation"
              required
              validateStatus={errors?.type?.message && 'error'}
              help={errors?.type?.message}
            >
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(e) => {
                      setValue('categories', []);
                      field.onChange(e);
                    }}
                    placeholder="Sélectionnez dans la liste"
                    disabled={isProcessing}
                  >
                    <Select.Option value="metier">Formation métier</Select.Option>
                    <Select.Option value="outil">Formation outil</Select.Option>
                  </Select>
                )}
              />
            </Form.Item>
            <Form.Item
              label="Intitulé"
              required
              validateStatus={errors?.title?.message && 'error'}
              help={errors?.title?.message}
            >
              <Controller
                control={control}
                name="title"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Form.Item>
            <Form.Item
              label="Catégories"
              required
              validateStatus={errors?.categories?.message && 'error'}
              help={errors?.categories?.message}
            >
              <Controller
                name="categories"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={isProcessing}
                    mode="multiple"
                    placeholder="Sélectionnez dans la liste"
                    options={type === 'metier' ? jobCategories : toolCategories}
                  />
                )}
              />
            </Form.Item>
            {type === 'metier' ? (
              <Form.Item
                label="Description"
                required
                validateStatus={errors?.description?.message && 'error'}
                help={errors?.description?.message}
                extra="La description est affiché dans le menu de séléction des formations métiers du site"
              >
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <Input.TextArea autoSize={{ minRows: 3 }} {...field} disabled={isProcessing} />
                  )}
                />
              </Form.Item>
            ) : (
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Logiciel"
                    required
                    validateStatus={errors?.app_name?.message && 'error'}
                    help={errors?.app_name?.message}
                  >
                    <Controller
                      control={control}
                      name="app_name"
                      render={({ field }) => <Input {...field} disabled={isProcessing} />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Éditeur du logiciel"
                    required
                    validateStatus={errors?.app_editor?.message && 'error'}
                    help={errors?.app_editor?.message}
                  >
                    <Controller
                      control={control}
                      name="app_editor"
                      render={({ field }) => <Input {...field} disabled={isProcessing} />}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {type === 'metier' && (
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Form.Item
                    label="Type de pré-inscription"
                    validateStatus={errors?.registration_type?.message && 'error'}
                    help={errors?.registration_type?.message}
                  >
                    <Controller
                      control={control}
                      name="registration_type"
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={[
                            {
                              value: 'INTERNE',
                              label: 'Interne',
                            },
                            {
                              value: 'PARCOURSUP',
                              label: 'Parcoursup',
                            },
                          ]}
                          disabled={isProcessing}
                        />
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Lien de pré-inscription"
                    extra="Lien vers la page de pre-inscription à la formation en parcours continu. Ce lien remplace le formulaire interne."
                    validateStatus={errors?.registration_url?.message && 'error'}
                    help={errors?.registration_url?.message}
                  >
                    <Controller
                      control={control}
                      name="registration_url"
                      render={({ field }) => (
                        <Input {...field} disabled={isProcessing || registrationType === 'INTERNE'} />
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Form.Item
              label="Lien CPF"
              extra="Lien vers la page de recherche de Mon Compte Formation, si aucun lien n'est saisi le pictogramme CPF ne sera pas affiché sur la fiche de formation"
              validateStatus={errors?.cpf_url?.message && 'error'}
              help={errors?.cpf_url?.message}
            >
              <Controller
                control={control}
                name="cpf_url"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Form.Item>
            {type === 'outil' && (
              <Form.Item
                label="Lien de pré-inscription NetYParéo"
                extra="En saisissant un lien dans ce champ, la pré-inscription sera gérée par NetYParéo et non le Back-Office"
                validateStatus={errors?.ypareo_url?.message && 'error'}
                help={errors?.ypareo_url?.message}
              >
                <Controller
                  control={control}
                  name="ypareo_url"
                  render={({ field }) => <Input {...field} disabled={isProcessing} />}
                />
              </Form.Item>
            )}
            <Form.Item
              validateStatus={errors?.goals?.message && 'error'}
              help={errors?.goals?.message}
              label="Objectifs"
              required
            >
              <Controller
                control={control}
                name="goals"
                render={({ field }) => (
                  <Editor
                    ref={field.ref}
                    value={field.value}
                    onEditorChange={field.onChange}
                    disabled={isProcessing}
                    init={{
                      selector: 'textarea',
                      skin: false,
                      content_css: '/tiny.css',
                      min_height: 300,
                      menubar: false,
                      object_resizing: 'true',
                      resize: 'both',
                      plugins: ['autoresize link', 'table paste', 'lists link', 'searchreplace'],
                      toolbar: 'undo redo | bold italic underline | bullist | link | searchreplace',
                      link_class_list: [
                        { title: 'Par défaut', value: '' },
                        { title: 'Lien invisible', value: 'hidden-link' },
                      ],
                    }}
                  />
                )}
              />
            </Form.Item>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="Image"
                  required
                  validateStatus={errors?.image?.message && 'error'}
                  help={errors?.image?.message}
                  extra="Format JPG ou PNG recommandés, 2Mo max"
                >
                  <Controller
                    control={control}
                    name="image"
                    render={({ field }) => (
                      <ImgCrop
                        aspect={270 / 230}
                        rotate
                        zoom
                        modalTitle="Modifier l'image"
                        modalCancel="Annuler"
                        modalOk="Confirmer"
                        onModalOk={onImageChange}
                      >
                        <Upload.Dragger
                          name={field.name}
                          maxCount={1}
                          showUploadList={false}
                          height={150}
                          disabled={isProcessing}
                          accept="image/*"
                          style={{
                            border: errors?.image?.message && '1px solid #ff4d4f',
                          }}
                        >
                          {imagePreview ? (
                            <img
                              style={{
                                maxWidth: 'calc(100% - 32px)',
                                maxHeight: 118,
                                display: 'block',
                                margin: '0 auto',
                              }}
                              src={imagePreview}
                              alt=""
                            />
                          ) : (
                            <p className="ant-upload-hint">Sélectionnez ou déposez une image</p>
                          )}
                        </Upload.Dragger>
                      </ImgCrop>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="Plaquette d'information"
                  validateStatus={errors?.file?.message && 'error'}
                  help={errors?.file?.message}
                  extra="Format PDF uniquement, 4Mo max"
                >
                  <Controller
                    control={control}
                    name="file"
                    render={({ field }) => (
                      <Upload.Dragger
                        name={field.name}
                        maxCount={1}
                        showUploadList={false}
                        height={150}
                        disabled={isProcessing}
                        accept="application/pdf"
                        style={{
                          border: errors?.file?.message && '1px solid #ff4d4f',
                        }}
                        onChange={(file) => onFileChange(file)}
                      >
                        <p className="ant-upload-hint">
                          {field.value ? (
                            <>
                              <FilePdfOutlined style={{ fontSize: 30 }} />
                              <br />
                              {field.value.name}
                            </>
                          ) : (
                            'Sélectionnez ou déposez un fichier'
                          )}
                        </p>
                      </Upload.Dragger>
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Programme" key="program" forceRender>
            <Typography.Paragraph type="secondary">
              <blockquote style={{ marginTop: 0 }}>
                <ul>
                  <li>
                    Le programme est séparé en plusieurs blocs, chaque bloc à besoin d'un titre et de son contenu, pour
                    ajouter un nouveau bloc cliquez sur le bouton "Ajouter un bloc" en bas de la page
                  </li>
                </ul>
              </blockquote>
            </Typography.Paragraph>
            {program.map((item, index) => (
              <div key={item.id}>
                <Form.Item label="Intitulé du bloc" required>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={16} md={18}>
                      <Controller
                        control={control}
                        name={`program.${index}.title`}
                        defaultValue={item.title}
                        render={({ field }) => <Input {...field} disabled={isProcessing} />}
                      />
                    </Col>
                    <Col xs={24} sm={8} md={6}>
                      <Button icon={<DeleteOutlined />} block onClick={() => removeProgram(index)}>
                        Supprimer ce bloc
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item label="Contenu du bloc" required>
                  <Controller
                    control={control}
                    name={`program.${index}.content`}
                    defaultValue={item.content}
                    render={({ field }) => (
                      <Editor
                        ref={field.ref}
                        value={field.value}
                        onEditorChange={field.onChange}
                        disabled={isProcessing}
                        init={{
                          selector: 'textarea',
                          skin: false,
                          content_css: '/tiny.css',
                          min_height: 300,
                          menubar: false,
                          object_resizing: 'true',
                          resize: 'both',
                          plugins: ['autoresize link', 'table paste', 'lists link', 'searchreplace'],
                          toolbar: 'undo redo | bold italic underline | bullist | link | searchreplace',
                          link_class_list: [
                            { title: 'Par défaut', value: '' },
                            { title: 'Lien invisible', value: 'hidden-link' },
                          ],
                        }}
                      />
                    )}
                  />
                </Form.Item>
                <Divider />
              </div>
            ))}
            <Form.Item>
              <Button block onClick={() => appendProgram({ title: '', content: '' })}>
                Ajouter un bloc
              </Button>
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Contenu" key="content" forceRender>
            <Typography.Paragraph type="secondary">
              <blockquote style={{ marginTop: 0 }}>
                <ul>
                  <li>Les champs vides ne seront pas affichés sur le site</li>
                </ul>
              </blockquote>
            </Typography.Paragraph>
            <Form.Item
              validateStatus={errors?.duration_implementation?.message && 'error'}
              help={errors?.duration_implementation?.message}
              label="Durée & modalités de réalisation"
            >
              <Controller
                control={control}
                name="duration_implementation"
                defaultValue="<ul><li><strong>En continu</strong> : 700h en centre + 140h de stage en entreprise</li><li><strong>En alternance</strong> : 455h en centre + 1365h en entreprise (durée globale : 1 an)</li></ul><p>(démarrage d’une session en alternance à chaque début de session en continu ; voir ci-dessous)</p><ul><li>Parcours personnalisable selon niveau et projet professionnel</li></ul>"
                render={({ field }) => (
                  <Editor
                    ref={field.ref}
                    value={field.value}
                    onEditorChange={field.onChange}
                    disabled={isProcessing}
                    init={{
                      selector: 'textarea',
                      skin: false,
                      content_css: '/tiny.css',
                      min_height: 300,
                      menubar: false,
                      object_resizing: 'true',
                      resize: 'both',
                      plugins: ['autoresize link', 'table paste', 'lists link', 'searchreplace'],
                      toolbar: 'undo redo | bold italic underline | bullist | link | searchreplace',
                      link_class_list: [
                        { title: 'Par défaut', value: '' },
                        { title: 'Lien invisible', value: 'hidden-link' },
                      ],
                    }}
                  />
                )}
              />
            </Form.Item>
            <Form.Item
              validateStatus={errors?.prerequisite_access?.message && 'error'}
              help={errors?.prerequisite_access?.message}
              label="Prérequis & modalités d'accès"
            >
              <Controller
                control={control}
                name="prerequisite_access"
                defaultValue='<ul><li><strong>Prérequis </strong>: VOIR PLAQUETTE METIER</li><li><strong>Modalités d’accès </strong>: <a data-mce-href="https://arinfo.fr/nous-contacter" href="https://arinfo.fr/nous-contacter">centre</a>; admissibilité après étude personnalisée du profil et du projet professionnel (entretien, CV, motivation); admission en formation après validation du financement du parcours et signature du contrat individuel ou de la convention de formation ; délai d’accès variable selon le calendrier de la formation et le dispositif de financement mobilisé (entre 15 jours et 4 mois).</li></ul>'
                render={({ field }) => (
                  <Editor
                    ref={field.ref}
                    value={field.value}
                    onEditorChange={field.onChange}
                    disabled={isProcessing}
                    init={{
                      selector: 'textarea',
                      skin: false,
                      content_css: '/tiny.css',
                      min_height: 300,
                      menubar: false,
                      object_resizing: 'true',
                      resize: 'both',
                      plugins: ['autoresize link', 'table paste', 'lists link', 'searchreplace'],
                      toolbar: 'undo redo | bold italic underline | bullist | link | searchreplace',
                      link_class_list: [
                        { title: 'Par défaut', value: '' },
                        { title: 'Lien invisible', value: 'hidden-link' },
                      ],
                    }}
                  />
                )}
              />
            </Form.Item>
            <Form.Item
              validateStatus={errors?.prices_funding?.message && 'error'}
              help={errors?.prices_funding?.message}
              label="Tarifs & financements"
            >
              <Controller
                control={control}
                name="prices_funding"
                defaultValue='<ul><li><strong>Tarifs </strong>:</li></ul><p>Formation en continu : taux horaire de 15€HT par candidat</p><p>Financement personnel ou demandeurs d’emploi : tarifs spécifiques à consulter auprès du <a data-mce-href="https://arinfo.fr/nous-contacter" href="https://arinfo.fr/nous-contacter">centre</a> concerné pour un accompagnement personnalisé</p><p>Alternance : tarifs spécifiques basés sur les coûts contrats des branches professionnelles, sans coût pédagogique pour l’alternant (<a href="http://www.francecompetences.fr/fiche/apprentissage-nouvelle-version-du-referentiel-des-niveaux-de-prise-en-charge-des-contrats/" data-mce-href="http://www.francecompetences.fr/fiche/apprentissage-nouvelle-version-du-referentiel-des-niveaux-de-prise-en-charge-des-contrats/">site France Compétences</a>); consultez le <a data-mce-href="https://arinfo.fr/nous-contacter" href="https://arinfo.fr/nous-contacter">centre</a> concerné pour plus de précision<strong></strong></p><ul><li><strong>Financements et dispositifs&nbsp;: </strong>consultation de la <a data-mce-href="https://arinfo.fr/financer-sa-formation" href="https://arinfo.fr/financer-sa-formation">page</a> dédiée<br></li></ul>'
                render={({ field }) => (
                  <Editor
                    ref={field.ref}
                    value={field.value}
                    onEditorChange={field.onChange}
                    disabled={isProcessing}
                    init={{
                      selector: 'textarea',
                      skin: false,
                      content_css: '/tiny.css',
                      min_height: 300,
                      menubar: false,
                      object_resizing: 'true',
                      resize: 'both',
                      plugins: ['autoresize link', 'table paste', 'lists link', 'searchreplace'],
                      toolbar: 'undo redo | bold italic underline | bullist | link | searchreplace',
                      link_class_list: [
                        { title: 'Par défaut', value: '' },
                        { title: 'Lien invisible', value: 'hidden-link' },
                      ],
                    }}
                  />
                )}
              />
            </Form.Item>
            <Form.Item
              validateStatus={errors?.evaluation_validation?.message && 'error'}
              help={errors?.evaluation_validation?.message}
              label="Évaluation & validation"
            >
              <Controller
                control={control}
                name="evaluation_validation"
                defaultValue="<ul><li><strong>Evaluation des acquis </strong>: tout au long du parcours, tests d’acquisition des savoirs et mesures des savoir-faire lors de situations de mise en application pratique (TPs, projets).</li><li><strong>Fin de formation </strong>: attestation de fin de formation avec rappel des objectifs de formation et des modalités de réalisation (dates et durée de formation, lieu de formation)</li><li><strong>Validation </strong>: titre professionnel XXXX du Ministère chargé de l’Emploi (reconnu par l’Etat et inscrit au répertoire national des certifications professionnelles (RNCP)), de niveau XX (bac+XX) ; possibilité de validation du titre complet ou de validation partielle (certificat de compétences professionnelles (CCP))</li><li><strong>Modalités d’examen </strong>: modalités conformes au référentiel de certification du titre visé; mise en situation professionnelle (ou projet différé), résultats d’évaluation passées en formation, dossier professionnel (et annexes éventuelles) et entretien devant jury professionnel habilité; présentation détaillée des modalités de certification lors de l’entretien de candidature et en cours de parcours de formation.</li></ul>"
                render={({ field }) => (
                  <Editor
                    ref={field.ref}
                    value={field.value}
                    onEditorChange={field.onChange}
                    disabled={isProcessing}
                    init={{
                      selector: 'textarea',
                      skin: false,
                      content_css: '/tiny.css',
                      min_height: 300,
                      menubar: false,
                      object_resizing: 'true',
                      resize: 'both',
                      plugins: ['autoresize link', 'table paste', 'lists link', 'searchreplace'],
                      toolbar: 'undo redo | bold italic underline | bullist | link | searchreplace',
                      link_class_list: [
                        { title: 'Par défaut', value: '' },
                        { title: 'Lien invisible', value: 'hidden-link' },
                      ],
                    }}
                  />
                )}
              />
            </Form.Item>
            <Form.Item
              validateStatus={errors?.methods_means?.message && 'error'}
              help={errors?.methods_means?.message}
              label="Méthodes & moyens mobilisés"
            >
              <Controller
                control={control}
                name="methods_means"
                defaultValue='<ul><li><strong>Pédagogie </strong>: un apprentissage métier proactif, basé sur le concept du «&nbsp;learning by doing&nbsp;»&nbsp;(les apprenants sont placés au cœur du processus de formation, en situation d’apprentissage actif).</li><li><strong>Formations multimodales </strong>: en présentiel (en centre, 9h-17h) ou en téléprésentiel (à distance, en salle virtuelle, 9h-17h)&nbsp;; mixité des séquences de formation (mises en situation professionnelles, projets, classes inversées, ateliers...).</li><li><strong>Moyens pédagogiques </strong>: formateurs spécialisés dans leur domaine; plateforme pédagogique avec accès individuel aux ressources de formation et une progression personnalisée; outils de suivi collectif et individuels (espaces d’échanges et de partage en ligne, salles virtuelles, livrets); présentation détaillée des modalités pédagogiques lors de l’entretien de candidature.</li></ul><p><em><a data-mce-href="https://arinfo.fr/pedagogie" href="https://arinfo.fr/pedagogie">En savoir plus</a><br></em></p>'
                render={({ field }) => (
                  <Editor
                    ref={field.ref}
                    value={field.value}
                    onEditorChange={field.onChange}
                    disabled={isProcessing}
                    init={{
                      selector: 'textarea',
                      skin: false,
                      content_css: '/tiny.css',
                      min_height: 300,
                      menubar: false,
                      object_resizing: 'true',
                      resize: 'both',
                      plugins: ['autoresize link', 'table paste', 'lists link', 'searchreplace'],
                      toolbar: 'undo redo | bold italic underline | bullist | link | searchreplace',
                      link_class_list: [
                        { title: 'Par défaut', value: '' },
                        { title: 'Lien invisible', value: 'hidden-link' },
                      ],
                    }}
                  />
                )}
              />
            </Form.Item>
            <Form.Item
              validateStatus={errors?.satisfaction_success?.message && 'error'}
              help={errors?.satisfaction_success?.message}
              label="Satisfaction & réussite aux examens"
            >
              <Controller
                control={control}
                name="satisfaction_success"
                defaultValue='<ul><li><strong>Evaluation de la satisfaction des formations : </strong>collecte de la satisfaction des participations, en fin de formation, au travers de différents critères d’appréciation (durée de la formation, contenu des ressources, environnement de formation, formateurs, déroulement des travaux, investissement des participants) et recueil des avis sur le déroulement de la formation.</li><li><strong>Réussite aux examens : </strong>sous agrément de la Direccte, organisation des sessions d’examen de validation du titre professionnel, selon les modalités prévues au référentiel de certification; synthèse statistique de réussite en libre consultation dans chaque centre</li><li><strong>Les chiffres-clé 2020 : </strong>XX% de satisfaction et XX% de réussite aux examens</li></ul><p><a data-mce-href="https://arinfo.fr/pedagogie" href="https://arinfo.fr/pedagogie" data-mce-selected="inline-boundary"><em>En savoir plus</em></a><em></em></p>'
                render={({ field }) => (
                  <Editor
                    ref={field.ref}
                    value={field.value}
                    onEditorChange={field.onChange}
                    disabled={isProcessing}
                    init={{
                      selector: 'textarea',
                      skin: false,
                      content_css: '/tiny.css',
                      min_height: 300,
                      menubar: false,
                      object_resizing: 'true',
                      resize: 'both',
                      plugins: ['autoresize link', 'table paste', 'lists link', 'searchreplace'],
                      toolbar: 'undo redo | bold italic underline | bullist | link | searchreplace',
                      link_class_list: [
                        { title: 'Par défaut', value: '' },
                        { title: 'Lien invisible', value: 'hidden-link' },
                      ],
                    }}
                  />
                )}
              />
            </Form.Item>
            <Form.Item
              validateStatus={errors?.references_labels?.message && 'error'}
              help={errors?.references_labels?.message}
              label="Référencement & labels"
            >
              <Controller
                control={control}
                name="references_labels"
                defaultValue='<ul><li><strong>RNCP ou RSCH </strong>: XXXXX</li><li><strong>Code Certif Info </strong>: XXXXX</li><li><strong>Label </strong>: XXXXX</li></ul><p><em><a data-mce-href="https://arinfo.fr/pedagogie" href="https://arinfo.fr/pedagogie">En savoir plus sur les agréments et labels d’Arinfo I-maginer</a><br></em></p>'
                render={({ field }) => (
                  <Editor
                    ref={field.ref}
                    value={field.value}
                    onEditorChange={field.onChange}
                    disabled={isProcessing}
                    init={{
                      selector: 'textarea',
                      skin: false,
                      content_css: '/tiny.css',
                      min_height: 300,
                      menubar: false,
                      object_resizing: 'true',
                      resize: 'both',
                      plugins: ['autoresize link', 'table paste', 'lists link', 'searchreplace'],
                      toolbar: 'undo redo | bold italic underline | bullist | link | searchreplace',
                      link_class_list: [
                        { title: 'Par défaut', value: '' },
                        { title: 'Lien invisible', value: 'hidden-link' },
                      ],
                    }}
                  />
                )}
              />
            </Form.Item>
            <Form.Item
              validateStatus={errors?.after_training?.message && 'error'}
              help={errors?.after_training?.message}
              label={type === 'metier' ? "Poursuite d'études - Métiers visés / Débouchés" : 'Poursuite de formation'}
            >
              <Controller
                control={control}
                name="after_training"
                defaultValue='<ul><li><strong>Métiers visés </strong>: VOIR PLAQUETTE METIER</li><li><strong>Débouchés </strong>: insertion professionnelle; création d’entreprise</li><li><strong>Passerelles et poursuite d’études </strong>: vers des formations accessibles à partir d’un diplôme de niveau Bac+XX</li></ul><p>RETIRER LES BLOCS NON LIÉS <span class="aCOpRe">À</span> LA FORMATION<br></p><p>TEBDP : Titres pro. BIM Modeleur, chargé d’affaires en rénovation énergétique du bâtiment, conducteur de travaux, chargé d’affaires du bâtiment; licences dans le domaine du bâtiment, de l’architecture et de la rénovation énergétique</p><p>IMEP : Titres pro. Designer web ; BTS et licences ans le domaine du graphisme, de l’audio visuel, de l’animation, de la communication, de la publicité</p><p>DW : Titre pro. Développeur web et web mobile ; licences pro. dans le domaine de la communication web, de l’animation, du graphisme</p><p>DWWM : Titre pro. Concepteur développeur d’applications ; licences pro. dans le domaine du développement web, mobile, informatique</p><p>CDA : masters dans le domaine du développement, du développement web, mobile, des bases de données ; écoles d’ingénieurs</p>'
                render={({ field }) => (
                  <Editor
                    ref={field.ref}
                    value={field.value}
                    onEditorChange={field.onChange}
                    disabled={isProcessing}
                    init={{
                      selector: 'textarea',
                      skin: false,
                      content_css: '/tiny.css',
                      min_height: 300,
                      menubar: false,
                      object_resizing: 'true',
                      resize: 'both',
                      plugins: ['autoresize link', 'table paste', 'lists link', 'searchreplace'],
                      toolbar: 'undo redo | bold italic underline | bullist | link | searchreplace',
                      link_class_list: [
                        { title: 'Par défaut', value: '' },
                        { title: 'Lien invisible', value: 'hidden-link' },
                      ],
                    }}
                  />
                )}
              />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Accessibilité" key="availability" forceRender>
            <Form.Item>
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={8}>
                  <Controller
                    control={control}
                    name="customer_availability"
                    defaultValue={true}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        disabled={isProcessing}
                        onChange={(e) => field.onChange(e.target.checked)}
                      >
                        Disponible pour les particuliers
                      </Checkbox>
                    )}
                  />
                  <br />
                  <Controller
                    control={control}
                    name="company_availability"
                    defaultValue={true}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        defaultChecked={false}
                        disabled={isProcessing}
                        onChange={(e) => field.onChange(e.target.checked)}
                      >
                        Disponible pour les professionnels
                      </Checkbox>
                    )}
                  />
                </Col>
                <Col xs={24} lg={8}>
                  <Controller
                    control={control}
                    name="alternation_availability"
                    defaultValue={true}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        defaultChecked={false}
                        disabled={isProcessing}
                        onChange={(e) => field.onChange(e.target.checked)}
                      >
                        Disponible en alternance
                      </Checkbox>
                    )}
                  />
                  <br />
                  <Controller
                    control={control}
                    name="uninterrupted_availability"
                    defaultValue={true}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        defaultChecked={false}
                        disabled={isProcessing}
                        onChange={(e) => field.onChange(e.target.checked)}
                      >
                        Disponible en continu
                      </Checkbox>
                    )}
                  />
                </Col>
                <Col xs={24} lg={8}>
                  <Controller
                    control={control}
                    name="remote_availability"
                    defaultValue={true}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        defaultChecked={false}
                        disabled={isProcessing}
                        onChange={(e) => field.onChange(e.target.checked)}
                      >
                        Disponible en distanciel
                      </Checkbox>
                    )}
                  />
                  <br />
                  <Controller
                    control={control}
                    name="person_availability"
                    defaultValue={true}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        defaultChecked={false}
                        disabled={isProcessing}
                        onChange={(e) => field.onChange(e.target.checked)}
                      >
                        Disponible en présentiel
                      </Checkbox>
                    )}
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              validateStatus={errors?.disabled_availability?.message && 'error'}
              help={errors?.disabled_availability?.message}
              label="Accessibilité Handicap"
            >
              <Controller
                control={control}
                name="disabled_availability"
                defaultValue="<ul><li><strong>Lieux de formation </strong>: centres de formation conformes aux règles d’accessibilité aux personnes handicapées</li><li><strong>Aménagement et adaptation </strong>: accompagnement personnalisé ; mise en relation avec les éventuelles structures connexes nécessaires à la gestion du handicap dans le cadre de la mise en œuvre du projet de formation ; possibilité d’aménagement du parcours (exemples : accueil à temps partiel ou discontinu, conditions d’examen, tutorat spécifique, adaptation du poste de formation...).</li><li><strong>Contact Référente handicap </strong>: Mme Marie DELOUCHE (mdelouche@arinfo.fr)</li></ul>"
                render={({ field }) => (
                  <Editor
                    ref={field.ref}
                    value={field.value}
                    onEditorChange={field.onChange}
                    disabled={isProcessing}
                    init={{
                      selector: 'textarea',
                      skin: false,
                      content_css: '/tiny.css',
                      min_height: 300,
                      menubar: false,
                      object_resizing: 'true',
                      resize: 'both',
                      plugins: ['autoresize link', 'table paste', 'lists link', 'searchreplace'],
                      toolbar: 'undo redo | bold italic underline | bullist | link | searchreplace',
                      link_class_list: [
                        { title: 'Par défaut', value: '' },
                        { title: 'Lien invisible', value: 'hidden-link' },
                      ],
                    }}
                  />
                )}
              />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Centres et sessions" key="sessions" forceRender>
            <Space size="middle" direction="vertical" style={{ display: 'flex' }}>
              {allSessions && Array.isArray(allSessions) && allSessions.length !== 0 ? (
                <Alert
                  type="info"
                  showIcon
                  message="L'ajout des dates dans un centre précis est désactivé lorsque la catégorie « Tous les centres » n'est pas vide."
                />
              ) : null}
              <Collapse style={{ marginBottom: 20 }} className="paddingless_collapse" accordion>
                <Collapse.Panel header="Tous les centres" key="*">
                  <AllSessionsManager control={control} loading={isProcessing} />
                </Collapse.Panel>
                {agencies?.data
                  .sort((a, b) => sort(a, b, 'city'))
                  .map((agency) => (
                    <Collapse.Panel
                      collapsible={
                        allSessions && Array.isArray(allSessions) && allSessions.length !== 0 ? 'disabled' : null
                      }
                      header={agency.city}
                      key={agency._id}
                    >
                      <SessionsManager control={control} agency={agency} loading={isProcessing} />
                    </Collapse.Panel>
                  ))}
              </Collapse>
            </Space>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Référencement (SEO)" key="seo" forceRender>
            <Form.Item
              label="Permalien"
              required
              validateStatus={errors?.seo_slug?.message && 'error'}
              help={errors?.seo_slug?.message}
              extra="Le permalien est l'URL d'accès à la fiche de formation, il est recommandé de garder les permaliens de l'ancien site pour ne pas perdre de référencement. Pas de majuscule, pas de caractères spéciaux (accents compris), mettre des tirets à la place des espaces (ex : titre-professionnel-designer-web)"
            >
              <Controller
                control={control}
                name="seo_slug"
                render={({ field }) => <Input {...field} disabled={isProcessing} />}
              />
            </Form.Item>
            <Form.Item
              label="Description"
              required
              validateStatus={errors?.seo_description?.message && 'error'}
              help={errors?.seo_description?.message}
              extra="Une description rapide de de 160 caractères max"
            >
              <Controller
                control={control}
                name="seo_description"
                render={({ field }) => (
                  <Input {...field} placeholder="Description *" disabled={isProcessing} maxLength={160} />
                )}
              />
            </Form.Item>
            <Form.Item
              label="Mots-clés"
              required
              validateStatus={errors?.seo_tags?.message && 'error'}
              help={errors?.seo_tags?.message}
              extra="Les mots-clés sont utilisés dans le moteur de recherche interne du site en plus du référencement, pour les formations métiers les mots-clés sont également affichés dans le menu de séléction des formations métiers du site"
            >
              <Controller
                control={control}
                name="seo_tags"
                render={({ field }) => (
                  <Select
                    {...field}
                    mode="tags"
                    placeholder="Mots-clés *"
                    disabled={isProcessing}
                    dropdownStyle={{ display: 'none' }}
                  />
                )}
              />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Témoignages" key="testimonial" forceRender>
            <Typography.Paragraph type="secondary">
              <blockquote style={{ marginTop: 0 }}>
                <ul>
                  <li>
                    Pour ajouter un nouveau témoignage cliquez sur le bouton "Ajouter un témoignage" en bas de la page.
                  </li>
                </ul>
              </blockquote>
            </Typography.Paragraph>
            {testimonial.map((item, index) => (
              <Row gutter={[16, 16]} key={item.id}>
                <Col xs={24} md={24}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={14} md={16}>
                      <Form.Item label="Identité">
                        <Controller
                          control={control}
                          name={`testimonial.${index}.author`}
                          defaultValue={item.author}
                          render={({ field }) => <Input {...field} disabled={isProcessing} />}
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ marginTop: 30 }} xs={24} sm={10} md={8}>
                      <Button icon={<DeleteOutlined />} block onClick={() => removeTestimonial(index)}>
                        Supprimer ce témoignage
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item label="Source" required>
                    <Controller
                      control={control}
                      name={`testimonial.${index}.from`}
                      defaultValue={item.from}
                      render={({ field }) => (
                        <Select
                          {...field}
                          disabled={isProcessing}
                          placeholder="Sélectionnez dans la liste"
                          options={sources}
                          style={{ width: '100%' }}
                        />
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item label="Témoignage" required>
                    <Controller
                      control={control}
                      name={`testimonial.${index}.content`}
                      defaultValue={item.content}
                      render={({ field }) => (
                        <Input.TextArea {...field} disabled={isProcessing} style={{ minHeight: 150, margin: 0 }} />
                      )}
                    />
                  </Form.Item>
                  <Divider />
                </Col>
              </Row>
            ))}
            <Button
              onClick={() =>
                appendTestimonial({
                  author: '',
                  content: '',
                  from: 'arinfo',
                })
              }
              style={{ marginBottom: 24 }}
              block
              disabled={isProcessing}
            >
              Ajouter un témoignage
            </Button>
          </Tabs.TabPane>
        </Tabs>
        {Object.keys(errors).length !== 0 ? (
          <Form.Item>
            <Alert message="Des erreurs sont présentes" type="error" />
          </Form.Item>
        ) : null}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button className="gray-text" disabled={isProcessing}>
            <Link to="/arinfo/formations">Annuler</Link>
          </Button>
          <Button type="primary" htmlType="submit" loading={isProcessing}>
            Enregistrer
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default TrainingCreate;
