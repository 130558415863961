import { useMemo, useState } from 'react';
import { Card, Button, Typography, Image, Space, Empty, Upload, message, Popover, Row, Col } from 'antd';
import { useController, useFormContext } from 'react-hook-form';
import { ExclamationCircleFilled, SettingOutlined, UploadOutlined } from '@ant-design/icons';
import { Cloudinary } from '@cloudinary/url-gen';
import useFetch from '../../../../../hooks/useFetch';
import useLoading from '../../../../../hooks/useLoading';
import useInterval from '../../../../../hooks/useInterval';

const CLOUDINARY_URL = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`;

const cld = new Cloudinary({
  cloud: { cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME },
  url: { secure: true },
});

export default function CoverConfig() {
  const [coverAlertTimeout, setCoverAlertTimeout] = useState(0);
  const { isLoading, startLoading, endLoading } = useLoading();
  const { control, disabled } = useFormContext();
  const { field } = useController({ control, name: 'cover.image' });
  const { post } = useFetch();
  const image = useMemo(() => (!!field.value ? cld.image(field.value)?.toURL() : null), [field.value]);

  useInterval(() => {
    if (coverAlertTimeout - 1 < 0) {
      return null;
    }

    return setCoverAlertTimeout(coverAlertTimeout - 1);
  }, 1000);

  /**
   * Récupère une signature de téléversement pour Cloudinary
   * @param {File} file
   * @returns La signature générée avec ses métadonnées
   */
  const getSignature = async (file) => {
    const url = `${process.env.REACT_APP_BASE_URL_API_URL}/v2/medias/videos/signature`;
    const response = await post(url);

    if (response.status === 200) {
      const { timestamp, signature } = response.data;

      return {
        file: file,
        api_key: process.env.REACT_APP_CLOUDINARY_API_KEY,
        timestamp,
        signature,
      };
    }
  };

  /**
   * Vérifie si le fichier est une image et s'il ne dépasse pas la limite de 5 Mo
   * @param {File} file Fichier à téléverser
   * @param {object} [config] Configuration de la validation
   * @param {number} [config] Taille max du fichier autorisée
   * @returns `false` si le fichier ne rempli pas les conditions
   */
  const validateFile = async (file, config) => {
    const fileSize = file.size / 1024 / 1024;
    const maxUploadSize = config?.maxSize || 5;

    /**
     * Vérifie si le fichier ne dépasse pas la limite de 5 Mo
     */
    if (fileSize > maxUploadSize) {
      message.error(`Fichier trop lourd, maximum autorisé : ${maxUploadSize} Mo`);
      return false;
    }

    /**
     * Vérifie si le fichier est une image
     */
    if (!file.type.startsWith('image/')) {
      message.error('Fichier invalide, autorisé : Image');
      return false;
    }
  };

  return (
    <Card size="small" title={<Typography.Title level={5}>Image de couverture</Typography.Title>}>
      <Space.Compact direction="vertical" style={{ display: 'flex' }}>
        {image ? (
          <Image
            width="100%"
            height={200}
            style={{
              objectFit: 'cover',
              borderTop: '1px solid #d9d9d9',
              borderLeft: '1px solid #d9d9d9',
              borderRight: '1px solid #d9d9d9',
              borderTopLeftRadius: 2,
              borderTopRightRadius: 2,
              overflow: 'hidden',
            }}
            src={image}
          />
        ) : (
          <div
            style={{
              height: 100,
              width: '100%',
              borderTop: '1px solid #d9d9d9',
              borderLeft: '1px solid #d9d9d9',
              borderRight: '1px solid #d9d9d9',
              borderTopLeftRadius: 2,
              borderTopRightRadius: 2,
              overflow: 'hidden',
            }}
          >
            <Empty description={false} style={{ height: '100%', width: '100%' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
        <Button.Group>
          <Popover
            trigger={[]}
            visible={coverAlertTimeout >= 1}
            placement="bottom"
            style={{ maxWidth: 150 }}
            zIndex={50}
            title={
              <Row>
                <Col flex="auto">
                  <Space>
                    <ExclamationCircleFilled style={{ color: '#faad14' }} />
                    <Typography.Text>Attention</Typography.Text>
                  </Space>
                </Col>
                <Col flex="none">
                  <Typography.Text type="secondary">{coverAlertTimeout}s</Typography.Text>
                </Col>
              </Row>
            }
            content={
              <div style={{ maxWidth: 200 }}>
                <Typography.Text>
                  Vous avez modifié l'image de couverture, assurez-vous que la configuration reste valide.
                </Typography.Text>
              </div>
            }
          >
            <Button {...{ disabled }} icon={<SettingOutlined />} style={{ flexShrink: 0 }} disabled />
          </Popover>
          <Upload
            name="cover"
            className="ant-upload-block"
            action={CLOUDINARY_URL}
            showUploadList={false}
            multiple={false}
            accept="image/*"
            disabled={isLoading('cover_upload') || disabled}
            data={getSignature}
            beforeUpload={validateFile}
            onChange={({ file }) => {
              if (file.status === 'uploading') {
                /**
                 * Passe le statut de la clé en chargement
                 */
                startLoading('cover_upload');
              } else {
                /**
                 * Si une erreur survient pendant le téléversement, le statut de chargement est retiré
                 */
                if (file.status !== 'done') {
                  endLoading('cover_upload');
                }
              }

              /**
               * Si le téléversement est réussi, appel le callback post-téléversement
               */
              if (file.status === 'done' && file?.response) {
                setCoverAlertTimeout(5);
                field.onChange(file.response.public_id);
                endLoading('cover_upload');
              }
            }}
          >
            <Button {...{ disabled }} block icon={<UploadOutlined />} loading={isLoading('cover_upload')}>
              {image ? 'Remplacer' : 'Téléverser'}
            </Button>
          </Upload>
        </Button.Group>
      </Space.Compact>
    </Card>
  );
}
