import { useMemo } from 'react';
import { Card, Image, Space, Typography, Tooltip, Row, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';

import { getImageUrl } from '../../../../lib/cloudinary';

const ApplicationAiSelectionCard = ({ title, href, description, image, onClick, disabled, versions, aiTagColors }) => {
  const imageUrl = useMemo(() => (image ? getImageUrl(image) : null), [image]);

  return (
    <Link to={href}>
      <Card className="aiAppSelection" onClick={onClick} size="small">
        <Space size="small">
          {!!imageUrl && <Image preview={false} width={100} src={imageUrl} />}
          <Space direction="vertical" size="small">
            <Space>
              {disabled && (
                <Tooltip title="Cette application est désactivée" destroyTooltipOnHide={{ keepParent: false }}>
                  <LockOutlined style={{ color: 'red' }} />
                </Tooltip>
              )}
              <Typography.Text>{title}</Typography.Text>
            </Space>
            <Typography.Text type="secondary">{description}</Typography.Text>
            {versions?.length !== 0 && (
              <Row>
                {versions?.map((version) => (
                  <Tooltip title={version?.version?.value}>
                    <Tag color={aiTagColors[version.version.ai]}>{version?.version?.name}</Tag>
                  </Tooltip>
                ))}
              </Row>
            )}
          </Space>
        </Space>
      </Card>
    </Link>
  );
};

export default ApplicationAiSelectionCard;
