import { Fragment } from 'react';
import { Card, Divider, Spin, Typography } from 'antd';
import useSWR from 'swr';
import Category from './Category';

const PAGE_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/pages/inter-intra`;

const SWROptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const Categories = () => {
  const { data: categories, isValidating, mutate } = useSWR(`${PAGE_URL}/categories`, SWROptions);

  return (
    <Card size="small" title={<Typography.Title level={5}>Univers & formations</Typography.Title>}>
      <Spin spinning={isValidating}>
        {(categories?.data || []).map((category, index) => (
          <Fragment key={category.value}>
            <Category data={category} onChange={mutate} />
            {index < categories?.data?.length - 1 && <Divider />}
          </Fragment>
        ))}
      </Spin>
    </Card>
  );
};

export default Categories;
