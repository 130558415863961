const API = process.env.REACT_APP_BASE_URL_API_URL;

export const authRoutes = {
  login: `${API}/auth/global`,
};

export const cloudinaryRoutes = {
  usage: `${API}/next/private/cloudinary/usage`,
  refresh: `${API}/next/private/cloudinary/refresh`,
};

export const keysRoutes = {
  default: `${API}/next/private/global/keys`,
};

export const aiRoutes = {
  default: `${API}/next/private/ai/systems`,
  versions: `${API}/next/private/ai/versions`,
  keys: `${API}/next/private/ai/keys`,
  applications: `${API}/next/private/ai/applications`,
  activities: `${API}/next/private/ai/activities`,
  modules: `${API}/next/private/ai/modules`,
  prompts: `${API}/next/private/ai/prompts`,
  interactions: `${API}/next/private/ai/interactions`,
  conversations: `${API}/next/private/ai/interactions/conversations`,
  logs: `${API}/next/private/ai/logs`,
};

const uploadBaseURL = `${API}/upload`;
export const uploadRoutes = {
  image: `${uploadBaseURL}/image`,
  file: `${uploadBaseURL}/formao/file`,
  deleteFile: `${uploadBaseURL}/file`,
  largeFile: `${uploadBaseURL}/formao/file/large`,
};

const userBaseURL = `${API}/global/users`;
export const userRoutes = {
  default: userBaseURL,
  updateRole: `${userBaseURL}/update-role`,
  createAccount: `${userBaseURL}/create-account`,
  disableAccount: `${userBaseURL}/disable`,
  enableAccount: `${userBaseURL}/enable`,
  resetPassword: `${userBaseURL}/reset-password`,
  updatePassword: `${userBaseURL}/update-password`,
  avatar: `${userBaseURL}/avatar`,
};

const roleBaseURL = `${API}/global/roles`;
export const roleRoutes = {
  default: roleBaseURL,
  findOne: `${roleBaseURL}/search-role`,
  delete: `${roleBaseURL}/delete`,
  create: `${roleBaseURL}/create-role`,
  update: `${roleBaseURL}/update-role`,
};

const agencyBaseURL = `${API}/global/agencies`;
export const agencyRoutes = {
  default: agencyBaseURL,
  delete: `${agencyBaseURL}/delete`,
  create: `${agencyBaseURL}/create-agency`,
  update: `${agencyBaseURL}/update-agency`,
};

const postBaseURL = `${API}/arinfo/posts`;
export const postRoutes = {
  default: postBaseURL,
  findOne: `${postBaseURL}/search-post`,
  delete: `${postBaseURL}/delete-post`,
  create: `${postBaseURL}/create-post`,
  update: `${postBaseURL}/update-post`,
  enable: `${postBaseURL}/enable-post`,
  disable: `${postBaseURL}/disable-post`,
};

const projectBaseURL = `${API}/arinfo/projects`;
export const projectRoutes = {
  default: projectBaseURL,
  findOne: `${projectBaseURL}/search-project`,
  delete: `${projectBaseURL}/delete-project`,
  create: `${projectBaseURL}/create-project`,
  update: `${projectBaseURL}/update-project`,
};

const trainingBaseURL = `${API}/arinfo/trainings`;
export const trainingRoutes = {
  default: trainingBaseURL,
  findOne: `${trainingBaseURL}/search-training`,
  delete: `${trainingBaseURL}/delete-training`,
  disable: `${trainingBaseURL}/disable-training`,
  enable: `${trainingBaseURL}/enable-training`,
  create: `${trainingBaseURL}/create-training`,
  update: `${trainingBaseURL}/update-training`,
  createLearnMore: `${API}/next/private/arinfo/trainings/learn-more`,
  updateLearnMore: `${API}/next/private/arinfo/trainings/learn-more`,
  findOneLearnMore: `${API}/next/private/arinfo/trainings/learn-more`,
  deleteLearnMore: `${API}/next/private/arinfo/trainings/learn-more`,
  enableLearnMore: `${API}/next/private/arinfo/trainings/learn-more/enable`,
  disableLearnMore: `${API}/next/private/arinfo/trainings/learn-more/disable`,
};

const workshopBaseURL = `${API}/arinfo/workshops`;
export const workshopRoutes = {
  default: workshopBaseURL,
  registrations: `${workshopBaseURL}/registrations/search`,
  registrations: `${workshopBaseURL}/registrations/search`,
};

const workshopRegistrationBaseURL = `${API}/arinfo/workshops/registrations`;
export const workshopRegistrationRoutes = {
  default: workshopRegistrationBaseURL,
};

const statisticBaseURL = `${API}/arinfo/statistics`;
export const statisticRoutes = {
  default: statisticBaseURL,
  findOne: `${statisticBaseURL}/search-statistics`,
  delete: `${statisticBaseURL}/delete-statistics`,
  create: `${statisticBaseURL}/create-statistics`,
  update: `${statisticBaseURL}/update-statistics`,
};

const registrationsBaseURL = `${API}/arinfo/registrations`;
export const registrationsRoutes = {
  default: registrationsBaseURL,
  pdf: `${registrationsBaseURL}/pdf`,
};

const FAQBaseURL = `${API}/arinfo/faq`;
export const FAQRoutes = {
  default: FAQBaseURL,
  update: `${FAQBaseURL}/update-sections`,
};

const questionBaseURL = `${API}/posiao/questions`;
export const questionRoutes = {
  default: questionBaseURL,
  findOne: `${questionBaseURL}/search-question`,
  delete: `${questionBaseURL}/delete-question`,
  create: `${questionBaseURL}/create-question`,
  update: `${questionBaseURL}/update-question`,
  enable: `${questionBaseURL}/enable`,
  disable: `${questionBaseURL}/disable`,
};

const quizBaseURL = `${API}/posiao/quizzes`;
export const quizRoutes = {
  default: quizBaseURL,
  deletePending: `${quizBaseURL}/delete-pending`,
  deleteComplete: `${quizBaseURL}/delete-complete`,
  archives: `${quizBaseURL}/archives`,
  pdf: `${quizBaseURL}/generate-pdf`,
  email: `${quizBaseURL}/email`,
  create: `${quizBaseURL}/create-quiz`,
  sendResults: `${quizBaseURL}/send-results`,
  export: `${quizBaseURL}/export`,
};

const typeBaseURL = `${API}/posiao/types`;
export const typeRoutes = {
  default: typeBaseURL,
  enable: `${typeBaseURL}/enable`,
  disable: `${typeBaseURL}/disable`,
};
const evaluationsBaseURL = `${API}/next/private/posiao/evaluations`;
export const evaluationsRoutes = {
  default: evaluationsBaseURL,
};

const blogBaseURL = `${API}/v2/imaginelab/posts`;
export const blogRoutes = {
  default: blogBaseURL,
  findOne: `${blogBaseURL}/search-post`,
  create: `${blogBaseURL}/create-post`,
  update: `${blogBaseURL}/update-post`,
  delete: `${blogBaseURL}/delete-post`,
  enable: `${blogBaseURL}/enable`,
  disable: `${blogBaseURL}/disable`,
};

const imaginelabWorkshopBaseURL = `${API}/v2/imaginelab/workshops`;
export const imaginelabWorkshopRoutes = {
  default: imaginelabWorkshopBaseURL,
  findOne: `${imaginelabWorkshopBaseURL}/`,
  create: `${imaginelabWorkshopBaseURL}/create-workshop`,
  update: `${imaginelabWorkshopBaseURL}/update-workshop`,
  delete: `${imaginelabWorkshopBaseURL}/delete-workshop`,
  enable: `${imaginelabWorkshopBaseURL}/enable`,
  disable: `${imaginelabWorkshopBaseURL}/disable`,
};

const imaginelabBookingsBaseURL = `${API}/v2/imaginelab/bookings`;
export const imaginelabBookingsRoutes = {
  default: imaginelabBookingsBaseURL,
  status: `${imaginelabBookingsBaseURL}/status`,
  delete: `${imaginelabBookingsBaseURL}/delete`,
  pdf: `${imaginelabBookingsBaseURL}/download-pdf`,
};

const imaginelabDocumentsBaseURL = `${API}/v2/imaginelab/documents`;
export const imaginelabDocumentsRoutes = {
  default: imaginelabDocumentsBaseURL,
  create: `${imaginelabDocumentsBaseURL}/create-document`,
  enable: `${imaginelabDocumentsBaseURL}/enable`,
  disable: `${imaginelabDocumentsBaseURL}/disable`,
  delete: `${imaginelabDocumentsBaseURL}/delete-document`,
  update: `${imaginelabDocumentsBaseURL}/update-document`,
};

const studentBaseURL = `${API}/formao/students`;
export const studentRoutes = {
  default: studentBaseURL,
  resetPassword: `${studentBaseURL}/reset-password`,
  enableAccount: `${studentBaseURL}/enable`,
  disableAccount: `${studentBaseURL}/disable`,
  testEmail: `${studentBaseURL}/test-email`,
  import: `${studentBaseURL}/import`,
  downloadRules: `${studentBaseURL}/pdf-rules`,
};

const groupRoutesBaseURL = `${API}/formao/groups`;
export const groupRoutes = {
  default: groupRoutesBaseURL,
  enable: `${groupRoutesBaseURL}/enable`,
  disable: `${groupRoutesBaseURL}/disable`,
  referent: `${groupRoutesBaseURL}/referent`,
};

const moduleRoutesBaseURL = `${API}/formao/modules`;
export const moduleRoutes = {
  default: moduleRoutesBaseURL,
  enable: `${moduleRoutesBaseURL}/enable`,
  disable: `${moduleRoutesBaseURL}/disable`,
  checkTempAccess: `${moduleRoutesBaseURL}/check-temp-access`,
  import: `${moduleRoutesBaseURL}/import`,
};

const formationRoutesBaseURL = `${API}/formao/formations`;
export const formationRoutes = {
  default: formationRoutesBaseURL,
  enable: `${formationRoutesBaseURL}/enable`,
  disable: `${formationRoutesBaseURL}/disable`,
};

const documentRoutesBaseURL = `${API}/formao/documents`;
export const documentRoutes = {
  default: `${documentRoutesBaseURL}`,
  public: `${documentRoutesBaseURL}/public`,
};

const eventRoutesBaseURL = `${API}/formao/events`;
export const eventRoutes = {
  default: `${eventRoutesBaseURL}`,
  unique: `${eventRoutesBaseURL}/unique`,
  referent: `${eventRoutesBaseURL}/referent`,
  module: `${eventRoutesBaseURL}/module`,
};

const cpfRoutesBaseURL = `${API}/arinfo/cpf`;
export const cpfRoutes = {
  default: `${cpfRoutesBaseURL}`,
};

const fileBaseURL = `${API}/global/files`;
export const fileRoutes = {
  default: fileBaseURL,
};

const nextAgencyBaseURL = `${API}/next/private/arinfo/agencies`;
export const nextAgencyRoutes = {
  default: nextAgencyBaseURL,
};

const reportBaseURL = `${API}/next/private/formao/reports`;
export const reportRoutes = {
  default: reportBaseURL,
  getOne: `${reportBaseURL}/get-one`,
  search: `${reportBaseURL}/search`,
  abort: `${reportBaseURL}/abort`,
  pdf: `${reportBaseURL}/download`,
  groupedPdf: `${reportBaseURL}/download-all`,
  resend: `${reportBaseURL}/resend`,
};

const satisfactionReportBaseURL = `${API}/next/private/formao/satisfaction-reports`;
export const satisfactionReportRoutes = {
  default: satisfactionReportBaseURL,
  download: `${satisfactionReportBaseURL}/download`,
  downloadAll: `${satisfactionReportBaseURL}/download-all`,
  resend: `${satisfactionReportBaseURL}/resend`,
};

const reportFormBaseURL = `${API}/next/private/formao/reports-forms`;
export const reportFormRoutes = {
  default: reportFormBaseURL,
  preview: `${reportFormBaseURL}/preview`,
};

const packageBaseURL = `${API}/next/private/formao/packages`;
export const packageRoutes = {
  default: packageBaseURL,
};

const externalInterventionBaseURL = `${API}/next/private/global/external-interventions`;
export const externalInterventionRoutes = {
  default: externalInterventionBaseURL,
};
