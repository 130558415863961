import { Button, Card, Form, Select, Space, Tag, Typography } from 'antd';
import { Controller, useFormContext, useFormState, useWatch } from 'react-hook-form';
import { DeleteOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import { aiRoutes } from '../../../../lib/routes';

const AIBlock = ({ index, onRemove, authorizations }) => {
  const { control, setValue, disabled } = useFormContext();
  const { errors } = useFormState({ control: control });
  const system = useWatch({ control, name: `authorizations.${index}.system` });
  const { data: aiSystems, isAiSystemsValidating } = useSWR(aiRoutes.default);
  const { data: aiKeys, isValidating: isAiKeysValidating } = useSWR(
    !!system ? `${aiRoutes.default}/${system}/keys` : null,
  );

  return (
    <Card
      size="small"
      title="Accès à une IA"
      headStyle={{ background: '#fafafa' }}
      extra={[<Button size="small" icon={<DeleteOutlined />} onClick={onRemove} disabled={disabled} />]}
    >
      <Space direction="vertical" style={{ display: 'flex', width: '100%' }}>
        <Form.Item
          label="Système"
          required
          validateStatus={errors?.authorizations?.[index]?.system?.message && 'error'}
          help={errors?.authorizations?.[index]?.system?.message}
        >
          <Controller
            control={control}
            name={`authorizations.${index}.system`}
            render={({ field }) => (
              <Select
                {...field}
                onChange={(value) => {
                  setValue(`authorizations.${index}.keys`, []);
                  field.onChange(value);
                }}
                placeholder="Sélectionnez dans la liste"
                options={(aiSystems?.data || []).map((item) => ({
                  label: item.name,
                  value: item.id,
                  disabled: (authorizations.includes(item.id) && item.id !== system) || disabled,
                }))}
                loading={isAiSystemsValidating}
                disabled={disabled}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Clés API"
          required
          validateStatus={errors?.authorizations?.[index]?.keys?.message && 'error'}
          help={errors?.authorizations?.[index]?.keys?.message}
        >
          <Controller
            control={control}
            name={`authorizations.${index}.keys`}
            render={({ field }) => (
              <Select
                {...field}
                mode="multiple"
                placeholder="Sélectionnez dans la liste"
                loading={isAiKeysValidating}
                disabled={!system || disabled}
                optionLabelProp="label"
              >
                {(aiKeys?.data || []).map((item) => (
                  <Select.Option key={item._id} label={item.name} value={item._id}>
                    <Space direction="vertical">
                      <Typography.Text>{item.name}</Typography.Text>
                      {item?.restrictions?.length > 0 ? (
                        <Typography.Text ellipsis>
                          <Space>
                            {item?.restrictions.map((restriction, index) => (
                              <Tag key={index}>{restriction?.name || restriction}</Tag>
                            ))}
                          </Space>
                        </Typography.Text>
                      ) : (
                        <Typography.Text type="secondary">Aucune restriction</Typography.Text>
                      )}
                    </Space>
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Form.Item>
      </Space>
    </Card>
  );
};

export default AIBlock;
