import { Button, Card, Form, Input, Modal, Space, Typography } from 'antd';
import useSWR from 'swr';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import useFetch from '../../../../../hooks/useFetch';

const PAGE_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/pages/inter-intra`;

const defaultMetaValues = {
  description: '',
};

const defaultStructuredDataValues = {
  type: '',
};

const MetaConfig = ({ disabled, onFinish, defaultValues }) => {
  const [open, setOpen] = useState(false);
  const { control, handleSubmit, reset, setError } = useForm({
    defaultValues: { meta: defaultValues?.meta || defaultMetaValues },
  });
  const { errors } = useFormState({ control });
  const { put } = useFetch();

  useEffect(() => {
    if (open) {
      reset({ meta: defaultValues?.meta || defaultMetaValues });
    }
  }, [open]);

  const onSubmit = async (form) => {
    const newValues = { ...defaultValues, ...form };

    /**
     * Sauvegarde dans la DB
     */
    const body = JSON.stringify(newValues);
    const response = await put(`${PAGE_URL}/seo`, body);

    /**
     * Met à jour l'affichage
     */
    if (response.status === 200) {
      setOpen(false);
      return onFinish();
    } else {
      if (Object.keys(response.errors).length !== 0) {
        Object.entries(response.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }
  };

  return (
    <>
      <Modal
        visible={open}
        destroyOnClose
        title="Configurer les balises META"
        onCancel={() => setOpen(false)}
        onOk={handleSubmit(onSubmit)}
      >
        <Form noValidate layout="vertical">
          <Controller
            {...{ control }}
            name="meta.description"
            render={({ field }) => (
              <Form.Item
                label="Description"
                required
                tooltip="Résumez le contenu de la page en une phrase impactante (150 caractères max) pour améliorer la visibilité dans les moteurs de recherche."
                validateStatus={errors?.meta?.description?.message && 'error'}
                help={errors?.meta?.description?.message}
              >
                <Input.TextArea {...field} autoSize />
              </Form.Item>
            )}
          />
        </Form>
      </Modal>
      <Button block disabled={disabled} onClick={() => setOpen(true)}>
        Configurer les balises META
      </Button>
    </>
  );
};

const StructuredDataConfig = ({ disabled, onFinish, defaultValues }) => {
  const [open, setOpen] = useState(false);
  const { control, handleSubmit, reset, setError } = useForm({
    defaultValues: { structuredData: defaultValues?.structuredData || defaultStructuredDataValues },
  });
  const { errors } = useFormState({ control });
  const { put } = useFetch();

  useEffect(() => {
    if (open) {
      reset({ structuredData: defaultValues?.structuredData || defaultStructuredDataValues });
    }
  }, [open]);

  const onSubmit = async (form) => {
    const newValues = { ...defaultValues, ...form };

    /**
     * Sauvegarde dans la DB
     */
    const body = JSON.stringify(newValues);
    const response = await put(`${PAGE_URL}/seo`, body);

    /**
     * Met à jour l'affichage
     */
    if (response.status === 200) {
      setOpen(false);
      return onFinish();
    } else {
      if (Object.keys(response.errors).length !== 0) {
        Object.entries(response.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }
  };
  return (
    <>
      <Modal
        visible={open}
        destroyOnClose
        title="Configurer les données structurées"
        onCancel={() => setOpen(false)}
        onOk={handleSubmit(onSubmit)}
      ></Modal>
      <Button block disabled={disabled} onClick={() => setOpen(true)}>
        Configurer les données structurées
      </Button>
    </>
  );
};

const Seo = () => {
  const { data, isValidating, mutate } = useSWR(`${PAGE_URL}/seo`);
  const { updatedAt } = data?.data || {};

  return (
    <Card
      size="small"
      title={<Typography.Title level={5}>Référencement (SEO)</Typography.Title>}
      extra={
        updatedAt && (
          <Typography.Text type="secondary">MàJ le {format(new Date(updatedAt), 'dd/MM/yyyy à HH:mm')}</Typography.Text>
        )
      }
    >
      <Space direction="vertical" style={{ display: 'flex' }}>
        <MetaConfig disabled={isValidating || !data?.data} defaultValues={data?.data} onFinish={mutate} />
        <StructuredDataConfig disabled={isValidating || !data?.data} defaultValues={data?.data} onFinish={mutate} />
      </Space>
    </Card>
  );
};

export default Seo;
