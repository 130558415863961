import { memo, useMemo, useState } from 'react';
import { Col, Row, Button, Typography, Space, List, Tag, Skeleton, Modal } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  FileExcelOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { format, isSameDay } from 'date-fns';
import useSWR from 'swr';
import { useFieldArray, useFormContext } from 'react-hook-form';
import DateSchedule from './DateSchedule';
// import importScheduleTemplate from '../../../../../lib/import-schedule-template';

const LOCATIONS_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/agencies`;

const SWROptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const DateScheduleListItem = memo(({ data, onDelete, onEdit }) => {
  const { control, disabled } = useFormContext();
  const { data: locations, isValidating: isValidating } = useSWR(
    data.accessMode === 'Présentiel' ? LOCATIONS_URL : null,
    SWROptions,
  );

  const dateLocation = useMemo(() => {
    if (!locations?.data || !data?.location) {
      return null;
    }

    return (locations?.data || []).find((item) => item._id === data.location)?.city || null;
  }, [data, locations]);

  const [start, end] = useMemo(() => {
    const dateFormat = 'dd/MM/yyyy';

    return [format(new Date(data.range[0]), dateFormat), format(new Date(data.range[1]), dateFormat)];
  }, [data.range]);

  return (
    <List.Item>
      <Space direction="vertical" style={{ display: 'flex', width: '100%' }}>
        <Row style={{ width: '100%' }} wrap={false}>
          <Col flex="auto">
            <Typography.Text>
              Du {start} au {end}
            </Typography.Text>
          </Col>
          <Col flex="none">
            <Space>
              <Button {...{ disabled }} icon={<EditOutlined />} size="small" onClick={onEdit} />
              <Button {...{ disabled }} icon={<DeleteOutlined />} size="small" onClick={onDelete} />
            </Space>
          </Col>
        </Row>
        <Row wrap align="middle" gutter={[6, 6]}>
          <Col flex="none">
            <Tag style={{ margin: 0 }}>
              {['Remise à niveau', 'Entrée/Sortie'].includes(data.goal)
                ? data.goal
                : `Objectif ${data.goal.toLowerCase()}`}{' '}
              | {data.differenceInBusinessDaysWithoutHolidays}
              &nbsp;jour{data.differenceInBusinessDaysWithoutHolidays > 1 ? 's' : ''}
            </Tag>
          </Col>
          <Col flex="none">
            <Skeleton
              active
              loading={isValidating && !dateLocation}
              title={{ width: 100, style: { margin: 0, height: 21.6 } }}
              paragraph={false}
              style={{ marginBottom: 0, marginTop: 2.3 }}
            >
              <Tag style={{ margin: 0 }}>
                {data.accessMode}
                {data.accessMode === 'Présentiel' ? ` | ${dateLocation}` : ''}
              </Tag>
            </Skeleton>
          </Col>
          <Col flex="none">
            <Tag style={{ margin: 0 }}>
              {data.price.type === 'startAt' ? 'À partir de ' : ''}
              {data.price.value}€ HT
            </Tag>
          </Col>
          <Col flex="none">
            <Tag style={{ margin: 0 }}>
              <Space>
                {data.places?.type === 'infinite' || data.places?.value > 1 ? <CheckOutlined /> : <CloseOutlined />}
                Places disponibles
              </Space>
            </Tag>
          </Col>
        </Row>
      </Space>
    </List.Item>
  );
});

export default memo(function DateScheduleList({ mode, name }) {
  const { control, disabled } = useFormContext();
  const [dateScheduleForm, setDateScheduleForm] = useState({ open: false, action: null, item: null });
  const { fields, append, remove, update } = useFieldArray({ control, name });

  const handleAdd = (values) => {
    return append({ ...values });
  };

  const handleEdit = (values) => {
    const id = values?.id || dateScheduleForm.item?.id;
    const index = fields.findIndex((field) => field.id === id);

    if (index !== -1) {
      update(index, values);
    }
  };

  const handleDelete = (index) => {
    return Modal.confirm({
      title: 'Voulez-vous vraiment supprimer la période ?',
      content: 'Cette action est irréversible.',
      okText: 'Confirmer',
      okType: 'danger',
      onOk: async () => {
        remove(index);
      },
    });
  };

  return (
    <Space direction="vertical" style={{ display: 'flex', width: '100%' }}>
      <Row wrap={false} align="middle">
        <Col flex="auto">
          <Typography.Text strong>Dates programmées</Typography.Text>
        </Col>
        <Col flex="none">
          <Space>
            {/* <Button {...{ disabled }} icon={<FileExcelOutlined />} onClick={importScheduleTemplate}>
              Importer
            </Button> */}
            <Button
              {...{ disabled }}
              icon={<PlusOutlined />}
              onClick={() => setDateScheduleForm({ open: true, action: 'CREATE', item: null })}
            >
              Nouvelle période
            </Button>
          </Space>
        </Col>
      </Row>
      <DateSchedule
        {...{ ...dateScheduleForm, mode }}
        onCancel={() => setDateScheduleForm({ open: false, action: null, item: null })}
        onFinish={(values) => {
          if (dateScheduleForm.action === 'CREATE') {
            handleAdd(values);
          } else {
            handleEdit(values);
          }

          setDateScheduleForm({ open: false, action: null, item: null });
        }}
      />
      <List
        className="date-schedule-list"
        dataSource={fields
          .map((item, index) => ({ ...item, originIndex: index }))
          .sort((a, b) => {
            if (isSameDay(new Date(a.range[0]), new Date(b.range[0]))) {
              return new Date(a.range[1]) - new Date(b.range[1]);
            }

            return new Date(a.range[0]) - new Date(b.range[0]);
          })}
        size="small"
        bordered
        rowKey={(row) => row.id}
        pagination={{
          pageSize: 6,
          size: 'small',
          hideOnSinglePage: true,
        }}
        renderItem={(item) => (
          <DateScheduleListItem
            key={item.id}
            data={item}
            onDelete={() => handleDelete(item.originIndex)}
            onEdit={() => setDateScheduleForm({ open: true, action: 'EDIT', item })}
          />
        )}
      />
    </Space>
  );
});
