import { Button, Modal, Typography, Spin } from 'antd';
import { useFormContext, useWatch } from 'react-hook-form';
import { useState, useEffect } from 'react';
import axios from 'axios';

import { useAuth } from '../../../../authContext';

const PromptTestModal = ({ onConfirm, isOpen, onClose, input }) => {
  const [promptMessage, setPromptMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const { token } = useAuth();
  const { control } = useFormContext();
  const values = useWatch({ control });

  const generatePrompt = async () => {
    setLoading(true);
    try {
      // Envoi de la requête
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/ai/prompts/activity`,
        headers: { Authorization: `Bearer ${token}` },
        data: { application: values.application, modules: values.modules },
      });

      if (response.status === 200) {
        setPromptMessage(response.data.data);
        setLoading(false);
      }
    } catch (err) {
      console.log(err?.response?.data?.message || err?.message || err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      generatePrompt();
    } else {
      setPromptMessage('');
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        title={`Test de la génération de prompt`}
        visible={isOpen}
        onCancel={onClose}
        // onOk={handleSubmit(onConfirm)}
        width={750}
        footer={[
          <Button key="back" onClick={onClose}>
            Annuler
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={generatePrompt}>
            Tester à nouveau
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <Typography.Paragraph>
            <span dangerouslySetInnerHTML={{ __html: promptMessage?.replaceAll('\n', '<br/>') }} />
          </Typography.Paragraph>
        </Spin>
      </Modal>
    </>
  );
};

export default PromptTestModal;
