const hasVariable = (text, variable) => {
  const variableName = `\\{${variable}\\}`;
  const regex = new RegExp(variableName);

  return regex.test(text);
};

const replaceVariable = (text, variable, value) => {
  if (hasVariable(text, variable)) {
    const variableName = `\\{${variable}\\}`;
    const regex = new RegExp(variableName, 'g');

    return text.replace(regex, value);
  } else {
    return text;
  }
};

/**
 *
 * @param {string} content
 * @param {} variableList
 * @returns
 */
export const contentToHtml = (content, variableList) => {
  let parsedContent = content;

  for (let i = 0; i < variableList.length; i++) {
    const newText = `<span data-type="variable" data-variable-id="${variableList[i].variableId}" >${variableList[i].key}</span>`;

    parsedContent = replaceVariable(parsedContent, variableList[i].variableId, newText);
  }

  return parsedContent;
};

/**
 *
 * @param {string} htmlContent
 * @param {} variableList
 * @returns
 */
export const htmlToContent = (htmlContent, variableList) => {
  // Rejette si le contenu HTML est vide
  if (!htmlContent) {
    return;
  }

  // Récupère la liste des variables dans le texte
  const parser = new DOMParser();
  const content = parser.parseFromString(htmlContent, 'text/html');

  // Nettoie toutes les variables
  content.querySelectorAll('[data-type="variable"]').forEach((item) => {
    let variable;

    if (item.getAttribute('data-variable-ref')) {
      variable = variableList.find(
        (variableItem) => variableItem.variableId === item.getAttribute('data-variable-ref'),
      );
    } else {
      variable = variableList.find((variableItem) => variableItem.variableId === item.getAttribute('data-variable-id'));
    }

    item.textContent = `{${variable.variableId}}`;
  });

  return content.body.textContent.trim();
};
