import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Divider, InputNumber, List, Row, Select, Space, Switch, Typography } from 'antd';
import { memo, useState } from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';

const VARIABLE_TYPE_SELECTION = [
  {
    label: 'Champ utilisateur',
    value: 'USER_PROFILE',
    disabled: true,
  },
  {
    label: "Liste d'éléments",
    value: 'LIST',
  },
  {
    label: 'Nombre aléatoire',
    value: 'RANDOM_NUMBER',
  },
];

const USER_FIELD_SELECTION = [
  {
    label: 'Nom complet',
    value: 'USER_NAME',
  },
  {
    label: 'Formation',
    value: 'USER_FORMATION',
  },
];

const UserFieldVariable = ({ index }) => {
  const { control } = useFormContext();

  return (
    <Row gutter={(24, 24)}>
      <Col span={12}>
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Typography.Text>Nature du champ</Typography.Text>
          <Controller
            name={`variables.[${index}].field`}
            control={control}
            render={({ field }) => <Select {...field} style={{ width: '200px' }} options={USER_FIELD_SELECTION} />}
          />
        </Space>
      </Col>
    </Row>
  );
};

const ElementsListVariableItem = memo(({ item, index, onDelete, onChange, deleteDisabled }) => {
  return (
    <List.Item style={{ width: '100%' }}>
      <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Text
          ellipsis
          editable={{
            onChange: (value) => onChange(index, value),
            tooltip: 'Renommer',
          }}
        >
          {item}
        </Typography.Text>
        <Button onClick={() => onDelete(index)} size="small" icon={<DeleteOutlined />} disabled={deleteDisabled} />
      </Space>
    </List.Item>
  );
});
ElementsListVariableItem.displayName = 'ElementsListVariableItem';

const ElementsListVariable = ({ index }) => {
  const { control, watch, setValue } = useFormContext();
  const itemList = watch(`variables.[${index}].list`);
  const { errors } = useFormState({ control });

  const addElement = () => {
    setValue(`variables.[${index}].list`, [...itemList, 'Nouvel élément']);
  };

  const editElement = (itemIndex, value) => {
    const modifiedArray = [...itemList];
    modifiedArray[itemIndex] = value;
    setValue(`variables.[${index}].list`, [...modifiedArray]);
  };

  const removeElement = (itemIndex) => {
    if (itemList.length > 2) {
      const modifiedArray = [...itemList];
      modifiedArray.splice(itemIndex, 1);
      setValue(`variables.[${index}].list`, [...modifiedArray]);
    }
  };

  return (
    <Row gutter={(24, 24)} align="bottom">
      <Col span={12}>
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Typography.Text>Eléments de la liste</Typography.Text>
          <List
            bordered
            size="small"
            pagination={false}
            style={{ overflow: 'hidden' }}
            dataSource={itemList}
            renderItem={(item, index) => (
              <ElementsListVariableItem
                {...{ item, index }}
                onChange={editElement}
                onDelete={removeElement}
                deleteDisabled={(itemList?.length || []) <= 2}
              />
            )}
          />
          <Button onClick={addElement} icon={<PlusOutlined />} style={{ width: '100%' }}>
            Ajouter un élément
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

const RandomNumberVariable = ({ index }) => {
  const { control, watch, setValue } = useFormContext();
  const minValue = watch(`variables.[${index}].min`);
  const maxValue = watch(`variables.[${index}].max`);
  const isDecimal = watch(`variables.[${index}].isDecimal`);
  const digits = watch(`variables.[${index}].digits`);
  const { errors } = useFormState({ control });

  const onMinChange = (value, onChange) => {
    if (value < maxValue) {
      onChange(value);
    }
  };

  const onMaxChange = (value, onChange) => {
    if (value > minValue) {
      onChange(value);
    }
  };

  const onToggle = (value, onChange) => {
    if (!value) {
      let roundedMin = Math.round(minValue);
      const roundedMax = Math.round(maxValue);

      if (roundedMin >= roundedMax) {
        roundedMin = roundedMax - 1;
      }

      setValue(`variables.[${index}].min`, roundedMin);
      setValue(`variables.[${index}].max`, roundedMax);
    }
    onChange(value);
  };

  const onDigitChange = (value, onChange) => {
    const factor = Math.pow(10, value);

    let roundedMin = Math.round(minValue * factor);
    const roundedMax = Math.round(maxValue * factor);

    if (roundedMin >= roundedMax) {
      roundedMin = roundedMax - 1;
    }

    setValue(`variables.[${index}].min`, roundedMin / factor);
    setValue(`variables.[${index}].max`, roundedMax / factor);

    onChange(value);
  };

  return (
    <Row gutter={(24, 24)} align="bottom">
      <Col span={12}>
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Typography.Text>Valeur minimale</Typography.Text>
          <Controller
            name={`variables.[${index}].min`}
            control={control}
            render={({ field }) => (
              <InputNumber
                {...field}
                precision={isDecimal ? digits : 0}
                onChange={(value) => onMinChange(value, field.onChange)}
                step={isDecimal ? Math.pow(10, -digits) : 1}
                style={{ width: '200px' }}
              />
            )}
          />
          <Typography.Text>Valeur maximale</Typography.Text>
          <Controller
            name={`variables.[${index}].max`}
            control={control}
            render={({ field }) => (
              <InputNumber
                {...field}
                precision={isDecimal ? digits : 0}
                onChange={(value) => onMaxChange(value, field.onChange)}
                step={isDecimal ? Math.pow(10, -digits) : 1}
                style={{ width: '200px' }}
              />
            )}
          />
        </Space>
      </Col>
      <Col span={12}>
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Space>
            <Controller
              name={`variables.[${index}].isDecimal`}
              control={control}
              render={({ field }) => (
                <Switch checked={field.value} onChange={(value) => onToggle(value, field.onChange)} size="small" />
              )}
            />
            <Typography.Text>Accepter les valeurs décimales ?</Typography.Text>
          </Space>
          <Typography.Text>Nombre de chiffres après la virgule</Typography.Text>
          <Controller
            name={`variables.[${index}].digits`}
            control={control}
            render={({ field }) => (
              <InputNumber
                {...field}
                disabled={!isDecimal}
                onChange={(value) => onDigitChange(value, field.onChange)}
                style={{ width: '200px' }}
                min={1}
                max={10}
              />
            )}
          />
        </Space>
      </Col>
    </Row>
  );
};

const ModuleVariableListItem = memo(({ item, index, onDelete }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const [variableType, setVariableType] = useState(item.type);

  return (
    <List.Item style={{ padding: 0 }}>
      <Collapse ghost style={{ width: '100%' }}>
        <Collapse.Panel
          header={
            <div
              onKeyUp={(event) => {
                const isEnterKey = event.key === 'Enter' || event.code === 'Enter';

                if (isEnterKey && event.target.nodeName === 'TEXTAREA') {
                  event.preventDefault();
                }
              }}
              onKeyDown={(event) => {
                const isEnterKey = event.key === 'Enter' || event.code === 'Enter';

                if (isEnterKey && event.target.nodeName === 'TEXTAREA') {
                  event.preventDefault();
                }
              }}
              onClick={(event) => {
                const editButton = !!event.target.closest('.ant-typography-edit');

                if (editButton || event.target.nodeName === 'TEXTAREA') {
                  event.stopPropagation();
                }
              }}
            >
              <Controller
                name={`variables.[${index}].key`}
                control={control}
                render={({ field }) => <Typography.Text>{field.value}</Typography.Text>}
              />
            </div>
          }
        >
          <Space direction="vertical" style={{ display: 'flex' }}>
            <Space size="middle">
              <Typography.Text>Type de variable</Typography.Text>
              <Controller
                name={`variables.[${index}].type`}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    style={{ width: '200px' }}
                    onChange={(value) => {
                      field.onChange(value);
                      setVariableType(value);
                    }}
                    options={VARIABLE_TYPE_SELECTION}
                  />
                )}
              />
            </Space>
            <Divider />
            {variableType === 'RANDOM_NUMBER' && <RandomNumberVariable {...{ index }} />}
            {variableType === 'LIST' && <ElementsListVariable {...{ index }} />}
            {variableType === 'USER_PROFILE' && <UserFieldVariable {...{ index }} />}
          </Space>
        </Collapse.Panel>
      </Collapse>
    </List.Item>
  );
});

ModuleVariableListItem.displayName = 'ModuleVariableListItem';
export default ModuleVariableListItem;
