import { ArrowsAltOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, List, Space, Typography } from 'antd';
import { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

const AiSelectItem = memo(({ item, index, versions, onNotFound, onDelete }) => {
  const { control } = useFormContext();
  const ai = useWatch({ control, name: 'ai' });
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id,
    disabled: ai?.length <= 1,
  });
  const version = versions.find((versionItem) => versionItem._id === item.version);

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    backgroundColor: '#ffffff',
  };

  if (!version) {
    onNotFound();
    return null;
  }

  return (
    <List.Item ref={setNodeRef} {...{ style }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          flexFlow: 'row nowrap',
        }}
      >
        <Typography.Text>{version.name}</Typography.Text>
        <Space>
          <Button
            {...{ ...attributes, ...listeners }}
            size="small"
            disabled={ai?.length <= 1}
            icon={<ArrowsAltOutlined style={{ transform: 'rotate(-45deg)' }} />}
          />
          <Button size="small" icon={<DeleteOutlined />} onClick={onDelete} />
        </Space>
      </div>
    </List.Item>
  );
});

AiSelectItem.displayName = 'AiSelectItem';
export default AiSelectItem;
