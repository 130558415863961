import { Button, Tabs, Popconfirm, Space, Tooltip, List, message, Collapse } from 'antd';
import {
  CodeOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  LockOutlined,
  MessageOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { aiRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';

const { TabPane } = Tabs;
import Item, { Meta } from 'antd/lib/list/Item';
import { useAuth } from '../../../../authContext';
import { checkAuthorization } from '../../../../shared/utils';
import { useState } from 'react';

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const ModuleAiItem = ({ item, onMutate, workspace }) => {
  const { post, patch, remove } = useFetch();
  const { user } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);

  const enableModule = async () => {
    setIsProcessing(true);

    const results = await patch(aiRoutes.modules + '/enable/' + item._id);

    if (results.status === 200) {
      onMutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const disableModule = async () => {
    setIsProcessing(true);

    const results = await patch(aiRoutes.modules + '/disable/' + item._id);

    if (results.status === 200) {
      onMutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const duplicateModule = async () => {
    setIsProcessing(true);

    const results = await post(
      aiRoutes.modules + '/' + item._id + '/copy',
      JSON.stringify({ title: item.title + ' (copy)' }),
    );

    if (results.status === 201) {
      onMutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const deleteModule = async () => {
    setIsProcessing(true);

    const results = await remove(aiRoutes.modules + '/' + item._id);

    if (results.status === 200) {
      onMutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  return (
    <Item
      actions={[
        <Space>
          {item.disabled
            ? workspace === 'general'
              ? checkAuthorization(user, 'ai', 'generalModules', 'enable') && (
                  <Tooltip title="Activer le module" destroyTooltipOnHide={{ keepParent: false }}>
                    <Popconfirm {...popconfirmProps} onConfirm={enableModule}>
                      <Button icon={<UnlockOutlined />} disabled={isProcessing} />
                    </Popconfirm>
                  </Tooltip>
                )
              : checkAuthorization(user, 'ai', 'modules', 'enable') && (
                  <Tooltip title="Activer le module" destroyTooltipOnHide={{ keepParent: false }}>
                    <Popconfirm {...popconfirmProps} onConfirm={enableModule}>
                      <Button icon={<UnlockOutlined />} disabled={isProcessing} />
                    </Popconfirm>
                  </Tooltip>
                )
            : workspace === 'general'
            ? checkAuthorization(user, 'ai', 'generalModules', 'disable') && (
                <Tooltip title="Désactiver le module" destroyTooltipOnHide={{ keepParent: false }}>
                  <Popconfirm {...popconfirmProps} onConfirm={disableModule}>
                    <Button icon={<LockOutlined />} disabled={isProcessing} />
                  </Popconfirm>
                </Tooltip>
              )
            : checkAuthorization(user, 'ai', 'modules', 'disable') && (
                <Tooltip title="Désactiver le module" destroyTooltipOnHide={{ keepParent: false }}>
                  <Popconfirm {...popconfirmProps} onConfirm={disableModule}>
                    <Button icon={<LockOutlined />} disabled={isProcessing} />
                  </Popconfirm>
                </Tooltip>
              )}
          {workspace === 'general'
            ? checkAuthorization(user, 'ai', 'generalModules', 'update') && (
                <Tooltip title="Modifier le module" destroyTooltipOnHide={{ keepParent: false }}>
                  <Link to={`/ia/modules/${workspace}/modifier-un-module/${item._id}`}>
                    <Button icon={<EditOutlined />} disabled={isProcessing} />
                  </Link>
                </Tooltip>
              )
            : checkAuthorization(user, 'ai', 'modules', 'update') && (
                <Tooltip title="Modifier le module" destroyTooltipOnHide={{ keepParent: false }}>
                  <Link to={`/ia/modules/${workspace}/modifier-un-module/${item._id}`}>
                    <Button icon={<EditOutlined />} disabled={isProcessing} />
                  </Link>
                </Tooltip>
              )}
          {workspace === 'general'
            ? checkAuthorization(user, 'ai', 'generalModules', 'create') && (
                <Tooltip title="Dupliquer le module" destroyTooltipOnHide={{ keepParent: false }}>
                  <Popconfirm {...popconfirmProps} onConfirm={duplicateModule}>
                    <Button icon={<CopyOutlined />} disabled={isProcessing} />
                  </Popconfirm>
                </Tooltip>
              )
            : checkAuthorization(user, 'ai', 'modules', 'create') && (
                <Tooltip title="Dupliquer le module" destroyTooltipOnHide={{ keepParent: false }}>
                  <Popconfirm {...popconfirmProps} onConfirm={duplicateModule}>
                    <Button icon={<CopyOutlined />} disabled={isProcessing} />
                  </Popconfirm>
                </Tooltip>
              )}
          {workspace === 'general'
            ? checkAuthorization(user, 'ai', 'generalModules', 'delete') && (
                <Tooltip title="Supprimer le module" destroyTooltipOnHide={{ keepParent: false }}>
                  <Popconfirm {...popconfirmProps} onConfirm={deleteModule}>
                    <Button icon={<DeleteOutlined />} disabled={isProcessing} />
                  </Popconfirm>
                </Tooltip>
              )
            : checkAuthorization(user, 'ai', 'modules', 'delete') && (
                <Tooltip title="Supprimer le module" destroyTooltipOnHide={{ keepParent: false }}>
                  <Popconfirm {...popconfirmProps} onConfirm={deleteModule}>
                    <Button icon={<DeleteOutlined />} disabled={isProcessing} />
                  </Popconfirm>
                </Tooltip>
              )}
        </Space>,
      ]}
    >
      <Meta
        avatar={
          item?.variables?.length ? (
            <CodeOutlined style={{ fontSize: '48px' }} />
          ) : (
            <MessageOutlined style={{ fontSize: '48px' }} />
          )
        }
        title={item.title}
        description={
          item?.activities?.length
            ? `Ce module est utilisé dans ${item.activities.length} activité(s)`
            : "Ce module n'est utilisé dans aucune activité"
        }
      />
    </Item>
  );
};

export default ModuleAiItem;
