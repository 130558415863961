import { Checkbox, DatePicker, Form, InputNumber, Modal, Select } from 'antd';
import { memo, useEffect, useMemo } from 'react';
import { search, validator } from '@i-maginexr/js';
import useSWR from 'swr';
import moment from 'moment';
import { differenceInBusinessDays, isSameDay, isWithinInterval, isWeekend } from 'date-fns';
import { Controller, useForm, useFormContext, useFormState, useWatch } from 'react-hook-form';
import useHolidays from '../../../../../hooks/useHolidays';

const LOCATIONS_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/agencies`;

const defaultValues = {
  range: [],
  goal: null,
  accessMode: null,
  location: null,
  price: {
    type: 'fixed',
    value: null,
  },
  places: {
    type: 'infinite',
    value: null,
  },
};

const SWROptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

// TODO : Déplacer dans npm-js
const findHolidaysInRange = (startDate, endDate, holidays) => {
  const holidaysInRange = holidays.filter((item) => {
    const holidayDate = new Date(item.date);

    if (isWeekend(holidayDate)) {
      return false;
    }

    if (isSameDay(holidayDate, new Date(startDate))) {
      return true;
    }

    if (isSameDay(holidayDate, new Date(endDate))) {
      return true;
    }

    return isWithinInterval(holidayDate, { start: new Date(startDate), end: new Date(endDate) });
  });

  return holidaysInRange;
};

export default memo(function DateSchedule({ open, action, onFinish, onCancel, item, mode }) {
  const { data: locations, isValidating: isLocationsValidating } = useSWR(open ? LOCATIONS_URL : null, SWROptions);
  const { control } = useFormContext();
  const [goals] = useWatch({ control, name: ['goals'] });
  const { control: dateFormControl, handleSubmit, reset, setError, trigger } = useForm({ defaultValues });
  const { errors } = useFormState({ control: dateFormControl });
  const dateFormAccessMode = useWatch({ control: dateFormControl, name: 'accessMode' });
  const holidays = useHolidays();

  const groupOptions = useMemo(() => {
    const options = [];

    if (goals.includes('Opérationnel')) {
      options.push({ label: 'Opérationnel', value: 'Opérationnel' });
    }

    if (goals.includes('Perfectionnement')) {
      options.push({ label: 'Perfectionnement', value: 'Perfectionnement' });
    }

    if (goals.includes('Complet')) {
      options.push({ label: 'Complet', value: 'Complet' });
    }

    if (goals.includes('Remise à niveau') && mode === 'Tutorat') {
      options.push({ label: 'Remise à niveau', value: 'Remise à niveau' });
    }

    if (goals.includes('Entrée/Sortie') && mode === 'Tutorat') {
      options.push({ label: 'Entrée/Sortie', value: 'Entrée/Sortie' });
    }

    return options;
  }, [goals, action, item]);

  useEffect(() => {
    if (open) {
      reset(action === 'EDIT' ? item : defaultValues);
    }
  }, [open, item]);

  const validate = (values) => {
    const errors = {};

    if (!('range' in values) || values.range === defaultValues.range) {
      errors.range = 'Ce champ est requis';
    } else {
      if (!Array.isArray(values.range) || values.range.length !== 2) {
        errors.range = 'Ce champ est requis';
      } else {
        if (values.range.some((value) => !validator.isValidDate(value))) {
          errors.range = 'Valeur invalide';
        }
      }
    }

    if (!('goal' in values) || values.goal === defaultValues.goal) {
      errors.goal = 'Ce champ est requis';
    } else {
      if (!goals.includes(values.goal)) {
        errors.goal = 'Valeur invalide';
      }
    }

    if (!('accessMode' in values) || values.accessMode === defaultValues.accessMode) {
      errors.accessMode = 'Ce champ est requis';
    } else {
      if (values.accessMode === 'Présentiel') {
        if (!('location' in values) || values.location === defaultValues.location) {
          errors.location = 'Ce champ est requis';
        }
      }
    }

    if (!('price' in values) || values.price === defaultValues.price) {
      errors.price = 'Ce champ est requis';
    } else {
      if (!validator.isStrictObject(values.price)) {
        errors.price = 'Valeur invalide';
      } else {
        if (!('type' in values.price)) {
          errors.price = 'Ce champ est requis';
        } else {
          if (!['startAt', 'fixed'].includes(values.price.type)) {
            errors.price = 'Valeur invalide';
          }
        }

        if (
          !('value' in values.price) ||
          (!values.price.value && values.price.value !== 0) ||
          values.price.value === defaultValues.price.value
        ) {
          errors.price = 'Ce champ est requis';
        } else {
          if (!['string', 'number'].includes(typeof values.price.value) || isNaN(values.price.value)) {
            errors.price = 'Valeur invalide';
          }
        }
      }
    }

    if (!('places' in values) || values.places === defaultValues.places) {
      errors.places = 'Ce champ est requis';
    } else {
      if (!validator.isStrictObject(values.places)) {
        errors.places = 'Valeur invalide';
      } else {
        if (!('type' in values.places)) {
          errors.places = 'Ce champ est requis';
        } else {
          if (!['fixed', 'infinite'].includes(values.places.type)) {
            errors.price = 'Valeur invalide';
          } else {
            if (values.places.type === 'fixed') {
              if (
                !('value' in values.places) ||
                (!values.places.value && values.places.value !== 0) ||
                values.places.value === defaultValues.places.value
              ) {
                errors.places = 'Ce champ est requis';
              } else {
                if (!['string', 'number'].includes(typeof values.places.value) || isNaN(values.places.value)) {
                  errors.places = 'Valeur invalide';
                }
              }
            }
          }
        }
      }
    }

    return errors;
  };

  const onSubmit = (form) => {
    const errors = validate(form);

    if (Object.keys(errors).length !== 0) {
      return Object.entries(errors).forEach(([key, value]) => {
        setError(key, { type: 'manual', message: value });
      });
    }

    const values = {
      ...form,
      differenceInBusinessDays: 0,
      differenceInBusinessDaysWithoutHolidays: 0,
    };

    values.differenceInBusinessDays = differenceInBusinessDays(values.range[1], values.range[0]);
    const holidaysInRange = findHolidaysInRange(values.range[0], values.range[1], holidays?.data)?.length || 0;
    values.differenceInBusinessDaysWithoutHolidays = values.differenceInBusinessDays - holidaysInRange;

    onFinish(values);
  };

  return (
    <Modal
      {...{ onCancel }}
      visible={open}
      title={action === 'CREATE' ? 'Nouvelle période' : 'Modifier une période'}
      destroyOnClose
      onOk={handleSubmit(onSubmit)}
    >
      <Form noValidate layout="vertical">
        <Form.Item
          label="Période"
          required
          validateStatus={errors?.range?.message && 'error'}
          help={errors?.range?.message}
        >
          <Controller
            control={dateFormControl}
            name="range"
            render={({ field }) => (
              <DatePicker.RangePicker
                {...field}
                format="DD/MM/YYYY"
                allowClear={false}
                value={field?.value?.length === 2 ? [moment(field.value[0]), moment(field.value[1])] : []}
                onChange={(momentRange) => {
                  const startDate = moment(momentRange[0]).startOf('day').toDate();
                  const endDate = moment(momentRange[1]).startOf('day').toDate();

                  return field.onChange([startDate, endDate]);
                }}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Objectif"
          required
          validateStatus={errors?.goal?.message && 'error'}
          help={
            !groupOptions || groupOptions.length === 0
              ? "Avant de configurer l'objectif, veuillez sélectionner les objectifs disponibles depuis les paramètres de la formation."
              : errors?.goal?.message || null
          }
        >
          <Controller
            control={dateFormControl}
            name="goal"
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Sélectionnez dans la liste"
                options={groupOptions}
                disabled={!groupOptions || groupOptions.length === 0}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Modalité"
          required
          validateStatus={errors?.accessMode?.message && 'error'}
          help={errors?.accessMode?.message}
        >
          <Controller
            control={dateFormControl}
            name="accessMode"
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Sélectionnez dans la liste"
                options={[
                  {
                    label: 'Distanciel',
                    value: 'Distanciel',
                  },
                  {
                    label: 'Présentiel',
                    value: 'Présentiel',
                  },
                ]}
              />
            )}
          />
        </Form.Item>
        {dateFormAccessMode === 'Présentiel' && (
          <Form.Item
            label="Centre pour le présentiel"
            required
            validateStatus={errors?.location?.message && 'error'}
            help={errors?.location?.message}
          >
            <Controller
              control={dateFormControl}
              name="location"
              render={({ field }) => (
                <Select
                  {...field}
                  showSearch
                  filterOption={(input, option) => search.inString(input, option.label)}
                  placeholder="Sélectionnez dans la liste"
                  loading={isLocationsValidating}
                  options={(locations?.data || [])
                    .sort((a, b) => a.city.localeCompare(b.city, 'fr'))
                    .map((location) => ({
                      label: location.city,
                      value: location._id,
                    }))}
                />
              )}
            />
          </Form.Item>
        )}
        <Form.Item
          label="Places disponibles"
          required
          tooltip="Indiquez le nombre de places disponibles pour cette période. Si le nombre de places est illimité ou inconnu, cochez la case « illimité » pour désactiver la saisie d'un nombre et faire apparaître la mention « Places disponibles »."
          validateStatus={errors?.places?.message && 'error'}
          help={errors?.places?.message}
        >
          <Controller
            control={dateFormControl}
            name="places"
            render={({ field }) => (
              <Controller
                control={dateFormControl}
                name="places.type"
                render={({ field }) => (
                  <Controller
                    control={dateFormControl}
                    name="places.value"
                    render={({ field: nestedField }) => (
                      <InputNumber
                        {...nestedField}
                        disabled={field.value === 'infinite'}
                        style={{ width: '100%' }}
                        onChange={(value) => {
                          trigger('places');

                          return nestedField.onChange(value);
                        }}
                        addonBefore={
                          <div style={{ width: 150, textAlign: 'left' }}>
                            <Checkbox
                              {...field}
                              checked={field.value === 'infinite'}
                              onChange={(event) => {
                                trigger('places');

                                if (event.target.checked) {
                                  return field.onChange('infinite');
                                }

                                return field.onChange('fixed');
                              }}
                            >
                              Illimité
                            </Checkbox>
                          </div>
                        }
                      />
                    )}
                  />
                )}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Prix HT"
          required
          tooltip="Saisissez le prix de la formation hors taxes. Si vous souhaitez indiquer un prix variable, cochez la case « Prix de départ ». Si la case n'est pas cochée, le montant sera considéré comme un prix fixe."
          validateStatus={errors?.price?.message && 'error'}
          help={errors?.price?.message}
        >
          <Controller
            control={dateFormControl}
            name="price"
            render={({ field }) => (
              <Controller
                control={dateFormControl}
                name="price.value"
                render={({ field }) => (
                  <InputNumber
                    {...field}
                    style={{ width: '100%' }}
                    addonAfter="€ HT"
                    onChange={(value) => {
                      trigger('price');

                      return field.onChange(value);
                    }}
                    addonBefore={
                      <Controller
                        control={dateFormControl}
                        name="price.type"
                        render={({ field: nestedField }) => (
                          <div style={{ width: 150, textAlign: 'left' }}>
                            <Checkbox
                              {...field}
                              checked={nestedField.value === 'startAt'}
                              onChange={(event) => {
                                trigger('price');

                                if (event.target.checked) {
                                  return nestedField.onChange('startAt');
                                }

                                return nestedField.onChange('fixed');
                              }}
                            >
                              Prix de départ
                            </Checkbox>
                          </div>
                        )}
                      />
                    }
                  />
                )}
              />
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});
