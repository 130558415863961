import { useParams } from 'react-router-dom';
import { Card, Button, Tabs, List, Typography, Input, Select } from 'antd';
import { ArrowLeftOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import qs from 'qs';
import { aiRoutes } from '../../../../lib/routes';
import tablePagination from '../../../../lib/tablePagination';
import { useAuth } from '../../../../authContext';
import ModuleAiItem from './ModuleAiItem';
import { checkAuthorization } from '../../../../shared/utils';
import { useMemo, useState } from 'react';
import { searchInString } from '../../../../lib/helpers';

const { TabPane } = Tabs;

const getQuery = (workspace, filters) => {
  return qs.stringify(
    {
      search: filters.query,
      type: filters.type,
      app: workspace,
    },
    { skipNulls: true, encoreValuesOnly: true, addQueryPrefix: true, arrayFormat: 'comma' },
  );
};

const ModuleAiList = () => {
  const { workspace } = useParams();
  const [values, setValues] = useState({
    query: null,
    type: null,
  });
  const query = useMemo(() => getQuery(workspace, values), [workspace, values]);
  const { data: application } = useSWR(`${aiRoutes.applications}/${workspace}`);
  const { data: modules, isModuleValidating, mutate } = useSWR(aiRoutes.modules + query);
  const enabledModules = modules?.data?.filter((module) => !module.disabled) || [];
  const disabledModules = modules?.data?.filter((module) => module.disabled) || [];
  const { user } = useAuth();

  return (
    <Card>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
        <Link to="/ia/modules">
          <Button icon={<ArrowLeftOutlined />}>Retour à la sélection des Applications</Button>
        </Link>
        {workspace === 'general'
          ? checkAuthorization(user, 'ai', 'generalModules', 'create') && (
              <Link to={`/ia/modules/${workspace}/nouveau-module`}>
                <Button icon={<PlusOutlined />}>Nouveau module</Button>
              </Link>
            )
          : checkAuthorization(user, 'ai', 'modules', 'create') && (
              <Link to={`/ia/modules/${workspace}/nouveau-module`}>
                <Button icon={<PlusOutlined />}>Nouveau module</Button>
              </Link>
            )}
      </div>
      {application?.data ? (
        <Typography.Title level={4}>Modules de l'application « {application?.data?.title} »</Typography.Title>
      ) : (
        <Typography.Title level={4}>Modules généraux</Typography.Title>
      )}
      <div className="activities-search-container">
        <Input
          placeholder="Rechercher"
          style={{ width: 300 }}
          value={values?.query}
          onChange={(e) => setValues({ ...values, query: e.target.value })}
          suffix={<SearchOutlined />}
          allowClear={true}
        />
        <Select
          placeholder="Type de module"
          style={{ minWidth: 200 }}
          value={values?.type}
          filterOption={(input, option) => searchInString(input, option.label)}
          onChange={(e) => setValues({ ...values, type: e })}
          options={[
            {
              label: 'Variable',
              value: 'variable',
            },
            {
              label: 'Script',
              value: 'script',
            },
          ]}
          allowClear
        />
      </div>
      <Tabs>
        <TabPane key="enabled" tab={`Actifs (${enabledModules.length})`}>
          <List
            bordered
            size="small"
            dataSource={enabledModules}
            loading={isModuleValidating}
            itemLayout="horizontal"
            pagination={{ ...tablePagination(enabledModules), size: 'small' }}
            renderItem={(item) => {
              return <ModuleAiItem item={item} onMutate={mutate} workspace={workspace} />;
            }}
          />
        </TabPane>
        <TabPane key="disabled" tab={`Inactifs (${disabledModules.length})`}>
          <List
            bordered
            size="small"
            dataSource={disabledModules}
            loading={isModuleValidating}
            itemLayout="horizontal"
            pagination={{ ...tablePagination(disabledModules), size: 'small' }}
            renderItem={(item) => {
              return <ModuleAiItem item={item} onMutate={mutate} workspace={workspace} />;
            }}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default ModuleAiList;
