import { useParams } from 'react-router-dom';
import { Card, Form, Button, Input, Typography, Space, message, Row, Col } from 'antd';
import { Link, Prompt } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import { useForm, Controller, useFormState, FormProvider } from 'react-hook-form';
import useSWR from 'swr';

import useFetch from '../../../hooks/useFetch';
import { aiRoutes } from '../../../lib/routes';
import PromptTestModal from './components/PromptTestModal';
import ModuleContent from './components/ModuleContent';
import { contentToHtml, htmlToContent } from '../lib/htmlParser';

const { Item } = Form;
const { Paragraph } = Typography;

const getDefaultValues = (module) => {
  if (!module || !module?.data) {
    return {};
  }

  const variables = (module.data?.variables || []).map((item) => {
    return {
      key: item.key,
      variableId: item.variableId,
      type: item.type,
      // Défauts pour les nombres aléatoires
      min: item?.min || 0,
      max: item?.max || 10,
      isDecimal: item?.isDecimal || false,
      digits: item?.digits || 2,
      // Défaut pour les listes d'éléments
      list: item?.list || ['Premier élément', 'Second élément'],
      // Défaut pour les champs utilisateurs
      field: item?.field || 'USER_NAME',
    };
  });

  return {
    title: module.data.title,
    content: contentToHtml(module.data.content, module.data?.variables || []),
    application: module.data?.application,
    variables,
    disabled: module.data?.disabled || false,
  };
};

const ModuleAiEdit = () => {
  const { workspace, id } = useParams();
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [promptTestModalOpen, setPromptTestModalOpen] = useState(false);
  const {
    data: module,
    isValidating,
    mutate,
  } = useSWR(`${aiRoutes.modules}/${id}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    keepPreviousData: false,
  });
  const defaultValues = useMemo(() => getDefaultValues(module), [module?.data]);
  const methods = useForm({ defaultValues });
  const { handleSubmit, reset, control, setValue, setError, formState } = methods;
  const { errors } = useFormState({ control });
  const { put } = useFetch();

  useEffect(() => {
    if (formState.isDirty) {
      setIsBlocking(true);
    }
  }, [formState.isDirty]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    // Formatte le texte du contenu
    form.content = htmlToContent(form.content, form.variables);

    const results = await put(`${aiRoutes.modules}/${id}`, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <PromptTestModal
          isOpen={!!promptTestModalOpen}
          input={promptTestModalOpen}
          onClose={() => setPromptTestModalOpen(false)}
        />
        <Form noValidate layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Paragraph>
          <Row gutter={(24, 24)}>
            <Col span={12}>
              <Item
                validateStatus={errors?.title?.message && 'error'}
                help={errors?.title?.message}
                label="Intitulé"
                required
              >
                <Controller
                  control={control}
                  name="title"
                  render={({ field }) => (
                    <Input {...field} placeholder="Entrez le titre du module" disabled={isProcessing} />
                  )}
                />
              </Item>
              <ModuleContent />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Link to={`/ia/modules/${workspace}`}>
                  <Button disabled={isProcessing}>Annuler</Button>
                </Link>
                <Space size="middle">
                  <Button onClick={() => setPromptTestModalOpen(true)}>Tester</Button>
                  <Button type="primary" htmlType="submit" loading={isProcessing}>
                    Enregistrer
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default ModuleAiEdit;
