import { useMemo, useState } from 'react';
import { Button, Table, Modal, Menu, Space, Popconfirm, Typography, Tooltip, Tag } from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  MessageOutlined,
  RedoOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { format } from 'date-fns';
import { capitalize } from 'lodash';

import { aiRoutes } from '../../../lib/routes';
import tablePagination from '../../../lib/tablePagination';
import { checkAuthorization, sort } from '../../../shared/utils';
import useColumnSearch from '../../../hooks/useColumnSearch';
import useFetch from '../../../hooks/useFetch';
import { useAuth } from '../../../authContext';

const { Item } = Menu;
import Link from 'antd/lib/typography/Link';

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const OUTCOME_LABELS = {
  SUCCESS: 'Réussite',
  ATTEMPT: 'Trop de tentatives',
  SECURITY: 'Rejet par la sécurité',
  MISC_ERROR: 'Erreur',
};

const OUTCOME_TAG_SELECTION = {
  SUCCESS: {
    label: OUTCOME_LABELS['SUCCESS'],
    icon: <CheckCircleOutlined />,
    color: 'success',
  },
  ATTEMPT: {
    label: OUTCOME_LABELS['ATTEMPT'],
    icon: <RedoOutlined />,
    color: 'warning',
  },
  SECURITY: {
    label: OUTCOME_LABELS['SECURITY'],
    icon: <ExclamationCircleOutlined />,
    color: 'default',
  },
  MISC_ERROR: {
    label: OUTCOME_LABELS['MISC_ERROR'],
    icon: <CloseCircleOutlined />,
    color: 'error',
  },
};

const JOB_AI_LABELS = {
  chatgpt: 'ChatGPT',
  mistral: 'Mistral AI',
};

const JOB_AI_TAG_SELECTION = {
  chatgpt: {
    label: JOB_AI_LABELS['chatgpt'],
    color: '#74AA9C',
  },
  mistral: {
    label: JOB_AI_LABELS['mistral'],
    color: '#ff7404',
  },
};

const INTERACTION_TYPES_LABELS = {
  CONVERSATION: 'Conversation',
};

const INTERACTION_TYPES_TAG_SELECTION = {
  CONVERSATION: {
    label: INTERACTION_TYPES_LABELS['CONVERSATION'],
    icon: <MessageOutlined />,
    color: 'gold',
  },
};

const USER_TYPES_LABELS = {
  ADMIN: 'Personnel Arinfo',
  STUDENT: 'Apprenant',
};

const USER_TYPES_TAG_SELECTION = {
  ADMIN: {
    label: USER_TYPES_LABELS['ADMIN'],
    icon: <TeamOutlined />,
    color: '#315397',
  },
  STUDENT: {
    label: USER_TYPES_LABELS['STUDENT'],
    icon: <UserOutlined />,
    color: '#ed6d63',
  },
};

const MESSAGES_ROLE_SELECTION = {
  user: 'Utilisateur',
  assistant: 'Assistant IA',
  system: 'Prompt',
};

const InteractionModal = ({ messages, visible, onClose }) => {
  return (
    <Modal visible={visible} onCancel={onClose} title="Messages de l'interaction" footer={false}>
      <Space direction="vertical" size="small" style={{ display: 'flex' }}>
        {(messages || []).map((item) => (
          <>
            <Space size="small">
              <Typography.Text strong>{MESSAGES_ROLE_SELECTION[item.role]}</Typography.Text>
              <Typography.Text type="secondary">({item.createdAt})</Typography.Text>
            </Space>
            <Typography.Paragraph>{item.content}</Typography.Paragraph>
          </>
        ))}
      </Space>
    </Modal>
  );
};

const PromptModal = ({ prompt, visible, onClose }) => {
  return (
    <Modal visible={visible} onCancel={onClose} title="Prompt de l'interaction" footer={false}>
      <Typography.Paragraph>
        <span dangerouslySetInnerHTML={{ __html: (prompt || '').replaceAll('\n', '<br/>') }} />
      </Typography.Paragraph>
    </Modal>
  );
};

const LogsAiList = ({ interactionLogs, isValidating, isProcessing, onProcessing, onMutate }) => {
  const { user } = useAuth();
  const [promptModalOpen, setPromptModalOpen] = useState(false);
  const [interactionModalOpen, setInteractionModalOpen] = useState(false);
  const { getColumnSearchProps } = useColumnSearch();
  const { patch, remove } = useFetch();

  const applicationFilter = useMemo(() => {
    return interactionLogs.reduce((acc, curr) => {
      const currentTitle = curr?.interaction?.activity?.application?.title || '';
      if (!acc.find((item) => item.value === currentTitle)) {
        return [
          ...acc,
          {
            text: currentTitle,
            value: currentTitle,
          },
        ];
      } else {
        return acc;
      }
    }, []);
  }, [interactionLogs]);

  const activityFilter = useMemo(() => {
    return interactionLogs.reduce((acc, curr) => {
      const currentTitle = curr?.interaction?.activity?.title || '';
      if (!acc.find((item) => item.value === currentTitle)) {
        return [
          ...acc,
          {
            text: currentTitle,
            value: currentTitle,
          },
        ];
      } else {
        return acc;
      }
    }, []);
  }, [interactionLogs]);

  const deleteLog = async (logId) => {
    onProcessing(true);

    const results = await remove(`${aiRoutes.logs}/${logId}`);

    if (results.status === 200) {
      onMutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const columns = [
    {
      title: 'Résultat',
      dataIndex: 'outcome',
      key: 'outcome',
      width: 175,
      filters: [
        { text: OUTCOME_LABELS['SUCCESS'], value: 'SUCCESS' },
        // { text: OUTCOME_LABELS['ATTEMPT'], value: 'ATTEMPT' },
        // { text: OUTCOME_LABELS['SECURITY'], value: 'SECURITY' },
        { text: OUTCOME_LABELS['MISC_ERROR'], value: 'MISC_ERROR' },
      ],
      onFilter: (value, record) => record.outcome === value,
      render: (value, record) => (
        <Tooltip title={record?.error || null} destroyTooltipOnHide={{ keepParent: false }}>
          <Tag
            style={{ width: '100%', textAlign: 'center' }}
            color={OUTCOME_TAG_SELECTION[value]?.color || 'default'}
            icon={OUTCOME_TAG_SELECTION[value]?.icon || null}
          >
            {OUTCOME_TAG_SELECTION[value]?.label || ''}
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: 'Application',
      dataIndex: ['interaction', 'activity', 'application', 'title'],
      key: 'application',
      filters: applicationFilter,
    },
    {
      title: 'Activité',
      dataIndex: ['interaction', 'activity', 'title'],
      key: 'activity',
      filters: activityFilter,
    },
    {
      title: 'Utilisateur',
      dataIndex: ['interaction', 'user'],
      key: 'user',
      width: 300,
      sorter: (a, b) => sort(a, b, 'interaction.user.last_name'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('interaction.user.last_name'),
      render: (value) => `${capitalize(value.first_name)} ${value.last_name.toUpperCase()}`,
    },
    {
      title: 'IA',
      dataIndex: 'ai',
      key: 'ai',
      width: 100,
      filters: [
        { text: JOB_AI_LABELS['chatgpt'], value: 'chatgpt' },
        { text: JOB_AI_LABELS['mistral'], value: 'mistral' },
      ],
      onFilter: (value, record) => record.ai.system === value,
      render: (value) => (
        <>
          {value?.release ? (
            <Tooltip title={value.release} destroyTooltipOnHide={{ keepParent: false }}>
              <Tag color={JOB_AI_TAG_SELECTION[value.system].color}>{JOB_AI_TAG_SELECTION[value.system].label}</Tag>
            </Tooltip>
          ) : (
            <>--</>
          )}
        </>
      ),
    },
    {
      title: 'Consommation',
      dataIndex: 'usage',
      key: 'usage',
      sorter: (a, b) =>
        Number((a.usage.inputTokens || 0) + (a.usage.outputTokens || 0)) -
        Number((b.usage.inputTokens || 0) + (b.usage.outputTokens || 0)),
      sortDirections: ['ascend', 'descend'],
      render: (value) => (
        <>
          <Space size="small">
            <Tooltip title="Tokens de prompt" destroyTooltipOnHide={{ keepParent: false }}>
              <Tag icon={<ArrowUpOutlined />}>{value.inputTokens}</Tag>
            </Tooltip>
            <Tooltip title="Tokens de complétion" destroyTooltipOnHide={{ keepParent: false }}>
              <Tag icon={<ArrowDownOutlined />}>{value.outputTokens}</Tag>
            </Tooltip>
          </Space>
        </>
      ),
    },
    {
      title: 'Tentative',
      dataIndex: 'attempt',
      key: 'attempt',
      sorter: (a, b) => Number(a.attempt || 0) - Number(b.attempt || 0),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Enregistré le',
      dataIndex: 'loggedAt',
      key: 'loggedAt',
      width: 200,
      align: 'center',
      render: (record) => format(new Date(record), 'dd/MM/yyyy à HH:mm'),
      sorter: (a, b) => sort(a, b, 'loggedAt'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Popconfirm {...popconfirmProps} onConfirm={() => deleteLog(record._id)}>
          <Button
            icon={<DeleteOutlined />}
            disabled={isProcessing || !checkAuthorization(user, 'ai', 'logs', 'delete')}
          />
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <InteractionModal
        messages={interactionModalOpen}
        visible={!!interactionModalOpen}
        onClose={() => setInteractionModalOpen(false)}
      />
      <PromptModal prompt={promptModalOpen} visible={!!promptModalOpen} onClose={() => setPromptModalOpen(false)} />
      <Table
        loading={!interactionLogs || isValidating || isProcessing}
        dataSource={interactionLogs}
        columns={columns}
        bordered
        size="small"
        rowKey={(row) => row._id}
        pagination={tablePagination(interactionLogs)}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <>
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                  <Space size="small">
                    <Tag
                      icon={INTERACTION_TYPES_TAG_SELECTION[record.interaction.type].icon}
                      color={INTERACTION_TYPES_TAG_SELECTION[record.interaction.type].color}
                    >
                      {INTERACTION_TYPES_TAG_SELECTION[record.interaction.type].label}
                    </Tag>
                    <Tag
                      icon={USER_TYPES_TAG_SELECTION[record.interaction.userType].icon}
                      color={USER_TYPES_TAG_SELECTION[record.interaction.userType].color}
                    >
                      {USER_TYPES_TAG_SELECTION[record.interaction.userType].label}
                    </Tag>
                  </Space>
                  <Space direction="vertical" size="small" style={{ display: 'flex' }}>
                    <Button onClick={() => setInteractionModalOpen(record.messages)} size="small" type="default">
                      Voir l'interaction
                    </Button>
                    <Link onClick={() => setPromptModalOpen(record.prompt)}>Voir le prompt</Link>
                  </Space>
                </Space>
              </>
            );
          },
        }}
      />
    </>
  );
};

export default LogsAiList;
