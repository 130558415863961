import { useRef } from 'react';
import { List } from 'antd';
import { useDrop } from 'react-dnd';

import ModuleAiLibraryItem from './ModulesAiLibraryItem';

const ModuleAiLibraryList = ({ listName, modules, onMove, onAppend, onDelete, onPreview }) => {
  const ref = useRef(null);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'CARD',
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item, monitor) => {
      const moduleIds = modules.map((module) => module._id);

      if (!moduleIds.includes(item._id)) {
        // Ajoute l'item à la nouvelle structure
        onAppend(item);

        // Retire l'item s'il vient d'une autre liste
        if (listName && item.container && listName !== item.container) {
          item.remove();
        }
      }
    },
  });

  drop(ref);

  return (
    <div ref={ref}>
      <List bordered size="small">
        {modules?.length
          ? modules.map((item, index) => (
              <ModuleAiLibraryItem
                key={`module.${item._id}`}
                index={index}
                moduleItem={{ ...item, index, container: listName }}
                onMove={onMove}
                onDelete={onDelete}
                onPreview={() => onPreview(item)}
              />
            ))
          : null}
      </List>
    </div>
  );
};

export default ModuleAiLibraryList;
