import { memo, useMemo } from 'react';
import useSWR from 'swr';
import { Button, Collapse, Divider, List, Modal, Space, Tag, Typography } from 'antd';
import { ClockCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import SelectFormationModal from './SelectFormationModal';

const FORMATIONS_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/trainings`;
const PAGE_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/pages/inter-intra`;

const SWROptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

// TODO : Déplacer dans npm-js
const orderByKey = (array, orderedArray) => {
  const order = {};

  for (const [index, name] of orderedArray.entries()) {
    order[name] = index;
  }

  return array.sort((a, b) => {
    if (!isNaN(order[a]) && !isNaN(order[b])) {
      return order[a] - order[b];
    }

    if (!isNaN(order[a]) && isNaN(order[b])) {
      return -1;
    }

    if (isNaN(order[a]) && !isNaN(order[b])) {
      return 1;
    }

    return 0;
  });
};

/**
 * Construit la liste des modes en chaîne de caractères séparés par un `/` avec l'ordre voulu.
 * @param {string[]} array
 * @returns Modes sous forme d'une chaîne de caractères.
 */
const buildModes = (object) => {
  const modes = [];

  if ('Inter' in object && !object['Inter'].disabled) {
    modes.push('Inter');
  }

  if ('Intra' in object && !object['Intra'].disabled) {
    modes.push('Intra');
  }

  if ('Tutorat' in object && !object['Tutorat'].disabled) {
    modes.push('Tutorat');
  }

  return modes.join('/');
};

/**
 * Construit la liste des objectifs en chaîne de caractères séparés par un `/` avec l'ordre voulu.
 * @param {string[]} array
 * @returns Objectifs sous forme d'une chaîne de caractères.
 */
const buildGoals = (array) => {
  const order = ['Opérationnel', 'Perfectionnement', 'Complet'];

  array = array.filter((item) => order.indexOf(item) !== -1);

  return orderByKey(array, order).join('/');
};

/**
 * Crée la liste des formations à afficher en ajoutant la formation d'origine dans chaque élément.
 * @param {array} formations
 * @param {array} dbFormations
 * @returns Liste des formations.
 */
const getListItems = (formations, dbFormations) => {
  /**
   * Si aucune formation provenant de la catégorie n'est trouvée, un tableau vide est renvoyé.
   */
  if (!formations || !Array.isArray(formations)) {
    return [];
  }

  /**
   * Si aucune formation provenant de la DB n'est trouvée, un tableau vide est renvoyé.
   */
  if (!dbFormations || !Array.isArray(dbFormations)) {
    return [];
  }

  /**
   * Reconstruit le tableau en ajoutant les formations d'origines.
   */
  return formations.flatMap(({ formation, ...rest }) => {
    const match = dbFormations.find((dbFormation) => dbFormation._id === formation);

    /**
     * Si aucune formation d'origine ne correspond, l'élément est considéré comme introuvable.
     * Un tableau vide est renvoyé pour ensuite être supprimé par flatMap().
     */
    if (!match) {
      return [];
    }

    return { ...rest, formation: match };
  });
};

const Formations = memo(({ category, loading, disabled }) => {
  const { data: categoryFormations, mutate } = useSWR(`${PAGE_URL}/categories/${category._id}/formations`, SWROptions);
  const { data: formations } = useSWR(FORMATIONS_URL, SWROptions);
  const { remove } = useFetch();
  const listItems = useMemo(() => {
    return getListItems(categoryFormations?.data || [], formations?.data || []);
  }, [categoryFormations, formations]);

  /**
   * Supprime la formation
   * @param {string} id ID de la formation a supprimer
   */
  const onDelete = async (id) => {
    return Modal.confirm({
      title: 'Voulez-vous vraiment supprimer la formation ?',
      content: 'Cette action est irréversible.',
      okText: 'Confirmer',
      okType: 'danger',
      onOk: async () => {
        /**
         * Supprime la formation
         */
        const response = await remove(`${PAGE_URL}/formations/${id}`, false);

        if (response.status === 200) {
          /**
           * Met à jour les données affichées
           */
          mutate();
        }
      },
    });
  };

  return (
    <Collapse collapsible={loading || disabled ? 'disabled' : null}>
      <Collapse.Panel
        key="1"
        header={`Formations (${listItems?.length || 0})`}
        className="paddingless_collapse"
        extra={
          <div onClick={(event) => event.stopPropagation()}>
            <SelectFormationModal {...{ category }} categoryFormations={listItems} />
          </div>
        }
      >
        <List
          dataSource={listItems.sort((a, b) => a.formation.title.localeCompare(b.formation.title, 'fr'))}
          renderItem={(item, index) => {
            const modes = buildModes(item.modes);
            const goals = buildGoals(item.goals);

            return (
              <List.Item style={{ padding: '12px 16px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Typography.Text ellipsis>{item.formation.title}</Typography.Text>
                  <Space>
                    <Tag key="duration" style={{ margin: 0 }}>
                      <Space>
                        <ClockCircleOutlined />
                        <Typography.Text>
                          De {item.minMaxDays[0]} à {item.minMaxDays[1]} jours
                        </Typography.Text>
                      </Space>
                    </Tag>
                    {modes !== '' && (
                      <Tag key="modes" style={{ margin: 0 }}>
                        {modes}
                      </Tag>
                    )}
                    {goals !== '' && (
                      <Tag key="goals" style={{ margin: 0 }}>
                        {goals}
                      </Tag>
                    )}
                    <Divider type="vertical" />
                    <Link to={`/arinfo/inter-intra/${item.category}/formations/${item.formation._id}`}>
                      <Button size="small" icon={<EditOutlined />} />
                    </Link>
                    <Button size="small" icon={<DeleteOutlined />} onClick={() => onDelete(item._id)} />
                  </Space>
                </div>
              </List.Item>
            );
          }}
        />
      </Collapse.Panel>
    </Collapse>
  );
});

Formations.displayName = 'Formations';
export default Formations;
