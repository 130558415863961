import { useEffect, useState } from 'react';
import useSWR from 'swr';

const useHolidays = () => {
  const [rawData, setRawData] = useState({ govData: [] });
  const fetcher = (resource, init) => fetch(resource, init).then((res) => res.json());
  const govApi = useSWR('https://calendrier.api.gouv.fr/jours-feries/metropole.json', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (govApi.data && typeof govApi.data === 'object') {
      const data = Object.entries(govApi.data).map(([key, value]) => ({ date: new Date(key), label: value }));

      setRawData((rest) => ({ ...rest, govData: data }));
    }
  }, [govApi.data]);

  return { data: [].concat(rawData.govData), isValidating: govApi.isValidating };
};

export default useHolidays;
