import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EllipsisOutlined,
  MessageOutlined,
  RobotOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Modal, Space, Spin, Tag, Typography } from 'antd';
import { memo, useState } from 'react';
import useSWR from 'swr';
import Link from 'antd/lib/typography/Link';

import { aiRoutes } from '../../../lib/routes';

const SWROptions = {
  revalidateOnReconnect: true,
  revalidateOnFocus: true,
  revalidateOnRefresh: false,
  refreshInterval: 5000,
};

const TYPE_TAG_SELECTION = {
  CONVERSATION: {
    label: 'Conversation',
    icon: <MessageOutlined />,
    color: 'gold',
  },
};

const STATUS_TAG_SELECTION = {
  INITIALIZING: {
    label: 'Initialisation',
    icon: <EllipsisOutlined />,
    color: 'green',
  },
  SUBMITTED: {
    label: "En attente de l'IA",
    icon: <RobotOutlined />,
    color: 'blue',
  },
  'IN PROGRESS': {
    label: "En attente de l'utilisateur",
    icon: <UserOutlined />,
    color: 'purple',
  },
  COMPLETED: {
    label: 'Terminée',
    icon: <CheckCircleOutlined />,
    color: 'success',
  },
  ERRORED: {
    label: 'Erreur',
    icon: <CloseCircleOutlined />,
    color: 'error',
  },
};

const PromptModal = ({ prompt, visible, onClose }) => {
  return (
    <Modal visible={visible} onCancel={onClose} title="Prompt de l'interaction" footer={false}>
      <Typography.Paragraph>
        <span dangerouslySetInnerHTML={{ __html: prompt.replaceAll('\n', '<br/>') }} />
      </Typography.Paragraph>
    </Modal>
  );
};

const InteractionExpandable = memo(({ interactionId, isExpanded }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { data, isValidating, mutate } = useSWR(
    isExpanded ? aiRoutes.interactions + '/' + interactionId : null,
    SWROptions,
  );

  return (
    <Spin spinning={!data?.data || isValidating}>
      {data?.data && (
        <>
          <PromptModal prompt={data.data.prompt} visible={modalOpen} onClose={() => setModalOpen(false)} />
          <Space direction="vertical" size="small" style={{ display: 'flex' }}>
            <Space size="small">
              <Tag icon={TYPE_TAG_SELECTION[data.data.type].icon} color={TYPE_TAG_SELECTION[data.data.type].color}>
                {TYPE_TAG_SELECTION[data.data.type].label}
              </Tag>
              <Tag
                icon={STATUS_TAG_SELECTION[data.data.status].icon}
                color={STATUS_TAG_SELECTION[data.data.status].color}
              >
                {STATUS_TAG_SELECTION[data.data.status].label}
              </Tag>
            </Space>
            <Space>
              <Typography.Text strong>Application :</Typography.Text>
              {data.data.activity.application.title}
            </Space>
            <Space>
              <Typography.Text strong>Activité :</Typography.Text>
              {data.data.activity.title}
            </Space>
            <Link onClick={() => setModalOpen(true)}>Voir le prompt</Link>
          </Space>
        </>
      )}
    </Spin>
  );
});

InteractionExpandable.displayName = 'InteractionExpandable';
export default InteractionExpandable;
