import { Button, Space, Popconfirm, Tag, message, Typography, Table, Dropdown, Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { formatDistanceToNowStrict } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { memo, useState } from 'react';

import tablePagination from '../../../lib/tablePagination';
import { useAuth } from '../../../authContext';
import { checkAuthorization, sortDate } from '../../../shared/utils';
import useFetch from '../../../hooks/useFetch';

import InteractionExpandable from './InteractionExpandable';
import AiUser from './AiUser';
const { Item } = Menu;

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const queueStatusTag = {
  OFFLINE: {
    label: 'Hors-ligne',
    color: 'error',
  },
  EMPTY: {
    label: 'Aucune tâche',
    color: 'default',
  },
  RUNNING: {
    label: 'En cours',
    color: 'success',
  },
  STOPPED: {
    label: 'Interrompu',
    color: 'warning',
  },
  HALTED: {
    label: 'Interrompu manuellement',
    color: 'error',
  },
  ERRORED: {
    label: "Erreur d'exécution",
    color: 'error',
  },
};

const AiQueueStatus = memo(({ status, elapsedTime }) => {
  return (
    <Space>
      <Tag color={queueStatusTag[status].color}>{queueStatusTag[status].label}</Tag>
      {status === 'RUNNING' && elapsedTime > 0 ? (
        <Typography.Text>
          Temps écoulé depuis le dernier job : {formatDistanceToNowStrict(new Date(elapsedTime), { locale: fr })}
        </Typography.Text>
      ) : null}
    </Space>
  );
});

const AiRejectedTable = memo(({ queue, status, elapsedTime, isProcessing, onProcessing, onMutate }) => {
  const { user } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { remove, post } = useFetch();

  const dropAllRejectedJobs = async () => {
    try {
      const response = await remove(`${process.env.REACT_APP_BASE_URL_API_AI_URL}/queue/rejected`);

      if (response.status !== 200) {
        message.error(
          response?.data?.message || 'Une erreur est survenue, la liste des jobs bloqués ne peut pas être vidée',
        );
      } else {
        onMutate();
        message.success('La liste des jobs bloqués a été vidée');
      }
    } catch (err) {
      console.log(err?.response?.data?.message || err?.message || err);
      message.error(
        err?.response?.data?.message ||
          err?.message ||
          'Une erreur est survenue, la liste des jobs bloqués ne peut pas être vidée',
      );
    }
  };

  const requeueRejectedJob = async (jobId) => {
    onProcessing(true);

    const results = await post(`${process.env.REACT_APP_BASE_URL_API_AI_URL}/queue/rejected/${jobId}`);

    if (results.status === 200) {
      onMutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const dropRejectedJob = async (jobId) => {
    onProcessing(true);

    const results = await remove(`${process.env.REACT_APP_BASE_URL_API_AI_URL}/queue/rejected/${jobId}`);

    if (results.status === 200) {
      onMutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const Actions = memo(({ record }) => {
    // const tempAccess = record?.tempAccesses.find((item) => item.user.toString() === user._id.toString());

    return (
      <Menu key={`menu-${record.id}`}>
        <Item key="retry">
          <Popconfirm {...popconfirmProps} onConfirm={() => requeueRejectedJob(record.id)}>
            Remets la tâche dans la liste
          </Popconfirm>
        </Item>
        {checkAuthorization(user, 'ai', 'api', 'dropItemFromQueue') && (
          <Item key="delete">
            <Popconfirm {...popconfirmProps} onConfirm={() => dropRejectedJob(record.id)}>
              Supprime la tâche
            </Popconfirm>
          </Item>
        )}
      </Menu>
    );
  });

  const columns = [
    {
      title: 'Utilisateur',
      dataIndex: 'item',
      key: 'user',
      render: (value) => {
        return <AiUser data={value} />;
      },
    },
    {
      title: 'Temps depuis soumission',
      dataIndex: 'elapsedTime',
      key: 'elapsedTime',
      sorter: (a, b) => sortDate(a, b, 'elapsedTime'),
      sortDirections: ['ascend', 'descend'],
      render: (value) => {
        if (value === null) {
          return <>--</>;
        } else {
          return <>{formatDistanceToNowStrict(new Date(value), { locale: fr })}</>;
        }
      },
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={<Actions record={record} />} placement="bottomRight" trigger={['click']}>
          <Button icon={<MoreOutlined />} disabled={isProcessing} />
        </Dropdown>
      ),
    },
  ];

  console.log(queue);

  return (
    <Space size="small" direction="vertical" style={{ display: 'flex' }}>
      <div style={{ textAlign: 'right' }}>
        {checkAuthorization(user, 'ai', 'api', 'dropQueue') && (
          <Popconfirm {...popconfirmProps} onConfirm={dropAllRejectedJobs} disabled={(queue || []).length === 0}>
            <Button type="primary" danger disabled={(queue || []).length === 0}>
              Vider la liste
            </Button>
          </Popconfirm>
        )}
      </div>
      <Table
        columns={columns}
        dataSource={queue || []}
        size="small"
        bordered
        rowKey={(row) => row.id}
        pagination={tablePagination(queue)}
        expandable={{
          expandedRowRender: (record, index, indent, expanded) => {
            if (record?.item?.interaction) {
              return <InteractionExpandable interactionId={record.item.interaction} isExpanded={expanded} />;
            }
          },
        }}
      />
    </Space>
  );
});

AiRejectedTable.displayName = 'AiRejectedTable';
export default AiRejectedTable;
